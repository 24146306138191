
export const geofenceExit = 'geofenceExit'
export const geofenceEnter = 'geofenceEnter'
export const deviceOverspeed = 'deviceOverspeed'
export const ignitionOn = 'ignitionOn'
export const ignitionOff = 'ignitionOff'
export const deviceFuelDrop = 'deviceFuelDrop'
export const driverChanged = 'driverChanged'
export const maintenance = 'maintenance'
export const inactivity = 'inactivity'
export const alarmSOS = 'sos'
export const alarmGPSAntennaCut = 'gpsAntennaCut'
export const alarmTow = 'tow'
export const alarmHighRpm = 'highRpm'
export const alarmHardAcceleration = 'hardAcceleration'
export const alarmHardBraking = 'hardBraking'
export const alarmHardCornering = 'hardCornering'
export const alarmPowerCut = 'powerCut'
export const alarmShock = 'shock'
export const alarmPowerOn = 'powerOn'
export const alarmTemperature = 'temperature'
export const alarmBreakdown = 'breakdown'
export const alarmDoor = 'door1'
export const alarmIdle = 'idle'
export const alarmUnidentifiedDriver = 'unidentifiedDriver'
export const alarmSensor = 'sensor'
export const alarmEngineOff = 'alarmEngineOff'
export const alarmEngineOn = 'alarmEngineOn'
export const alarmWeeklyDriving = 'alarmWeeklyDriving'

export const unitAlarmTypes = [
  alarmSOS,
  alarmGPSAntennaCut,
  alarmTow,
  alarmHighRpm,
  alarmHardAcceleration,
  alarmHardBraking,
  alarmHardCornering,
  alarmPowerCut,
  alarmShock,
  alarmPowerOn,
  alarmBreakdown,
  alarmDoor,
  alarmTemperature
]

export const customAlarmTypes = [
  alarmSOS,
  alarmGPSAntennaCut,
  alarmTow,
  alarmHighRpm,
  alarmHardAcceleration,
  alarmHardBraking,
  alarmHardCornering,
  alarmPowerCut,
  alarmShock,
  alarmPowerOn,
  alarmBreakdown,
  alarmDoor,
  alarmTemperature,
  alarmUnidentifiedDriver,
  alarmIdle,
  alarmSensor,
  alarmEngineOn,
  alarmEngineOff
]

export const traccarAlertTypes = [
  geofenceExit,
  geofenceEnter,
  deviceOverspeed,
  ignitionOn,
  ignitionOff,
  deviceFuelDrop,
  driverChanged,
  maintenance,
  inactivity
]

export const alertTypes = traccarAlertTypes.concat(customAlarmTypes)

export const unidentifiedDriverAlarmComputedId = 40
export const idleAlarmComputedId = 53
export const digitalPortAlarmComputedId = 55
export const calibrateTemperatureComputedId = 63
export const towAlarmComputedId = 65
