module.exports = {
  title: 'Pinme',
  /**
   * @type {boolean} true | false
   * @description Snap routes to road (road match)
   */
  matchRoutes: false,
  viewSpeedAlerts: true,
  showLabels: true,
  driverChatEnabled: false,
  fabricateIgnition: true,
  animateMarkers: true,
  maxMarkersForAnimation: 9000,
  showVehicleList: true,
  showSlider: true,
  experiment: true,
  debugRoutes: false,
  mapBoxRouteMatch: false,
  maxDevicesForAlerts: 300,
  checkFuelThresholds: false
}
