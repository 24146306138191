<template>
  <div style="height:100%">
    <navbar />
    <profile></profile>
    <div class="row">
      <side-bar />
      <settings-side-bar />
      <app-main class="divMain" />
    </div>
  </div>
</template>

<script>

import AppMain from './components/AppMain'
import SideBar from './components/SideBar'
import Navbar from './components/Navbar'
import Profile from './components/Profile'
import * as notifications from '../utils/notifications'
import * as event from '../events'
import { mapGetters } from 'vuex'
import { serverBus } from '@/main'
import SettingsSideBar from '@/layout/components/SettingsSideBar'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    SideBar,
    SettingsSideBar,
    Profile
  },
  data() {
    return {
      serviceWorker: null
    }
  },
  computed: {
    ...mapGetters(['loading'])
  },
  beforeDestroy() {
    serverBus.$off(event.newEventReceived, this.onEvent)
  },
  created() {
    this.$log.info('layout')
    serverBus.$on(event.newEventReceived, this.onEvent)
  },
  methods: {
    onEvent(event) {
      try {
        new Audio('/sound/doorbell.wav').play()
      } catch (e) {
        console.error(e)
      }
      this.$notify({
        title: this.$t('settings.alert_' + event.type),
        message: notifications.getMessage(event),
        type: 'info',
        duration: 5000
      })
      this.$store.dispatch('transient/addEvents', [event]).then(() => {})
    }
  }
}
</script>

<style scoped>
  .row {
    display: flex;
    height: 100%;
  }
  .divMain {
    width: 100%;
    height: 100%;
  }
</style>
