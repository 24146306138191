// noinspection JSNonASCIINames,NonAsciiCharacters
export default {
  'Condução Semanal': 'Conduccion semanal',
  'mais de 40%': 'Mas de 40%',
  'entre 20% a 40%': 'Entre 20% y 40%',
  'menos de 20%': 'Menos de 20%',
  'Inside or outside zones': 'Adentro o fuera de zonas',
  'Only inside zones': 'Solo adentro de zonas',
  'Only oustside zones': 'Solo afuera de zonas',
  viewEventsOnRoute: 'Mostrar eventos en la ruta',
  viewIdlePoints: 'Mostrar ralenti en la ruta',
  Nome: 'Nombre',
  'Allow poi collisions': 'Permitir solapar POIs',
  'Show poi circles': 'Mostrar circunferencia del poi',
  'Legacy Map': 'Mapa antiguo',
  Generator: 'Generador',
  Forklift: 'Carretilla',
  Traffic: 'Tráfico',
  'A data limite não pode ser anterior à data actual': 'La fecha limite no puede ser anterior a la actual',
  'Seleccione um veículo': 'Seleccione un vehículo',
  'Destination Address': 'Dirección de destino',
  'Copy Link': 'Copiar Enlace',
  'Tem a certeza que pretende remover todos os conductores?': 'Está seguro que desea eliminar todos los conductores?',
  'Remover Todos': 'Eliminar todos',
  Icon: 'Icono',
  'Enviámos un mensaje de confirmación a su móvil.': 'Hemos enviado un mensaje de confirmación a tu móvil',
  Warning: 'Aviso',
  'Whatsapp notifications': 'Alarmas por WhatsApp',
  Yes: 'Sí',
  No: 'No',
  Move: 'Mover',
  'Export excel with new passwords': 'Exportar excel con nuevas contraseñas',
  Selecionados: 'Seleccionados',
  Veículos: 'Vehículos',
  'Use road speed limit': 'Usar limite de la calle',
  Signs: 'Señales',
  Dark: 'Obscuro',
  Light: 'Claro',
  Streets: 'Calles',
  Satellite: 'Satélite',
  'Google Hybrid': 'Google Satélite',
  shareScreen: 'Compartir pantalla',
  Copiado: 'Copiado',
  copyClipboardMessage: 'copiado para a área de transferência (clipboard).',
  app: {
    connectionLost: 'Conexion terminada',
    reconnect: 'Desea restabelecer la conexión con el servidor?'
  },
  route: {
    tacho: 'Tacógrafo',
    alert_digital_port: 'Puerto Digital',
    max_digital_port_open_time: 'Tiempo máximo con puerto digital abierto (segundos)',
    scheduler: 'Informes automáticos',
    map: 'Mapa',
    dashboard: 'Dashboard',
    reports: 'Informes',
    report: 'Informe',
    report_trip_title: 'Informe de viajes',
    report_location_title: 'Informe de posiciones',
    report_zone_crossing: 'Informe de zonas',
    report_speeding: 'Informe de excesos de velocidad',
    report_refueling: 'Informe de cargas',
    report_fueldrop: 'Informe de caída de combustible',
    report_events: 'Informe de eventos',
    report_activity: 'Informe de actividad',
    report_kms: 'Informe de kms',
    report_speeding_beta: 'Informe de excesos de velocidad BETA',
    report_tolls: 'Informe de peajes',
    customreport_vistawaste_activity_title: 'Informe de Actividade',
    settings: 'Ajustes',
    duration: 'Duracción',
    nodata: 'no hay datos para el periodo seleccionado.',
    alerts: 'Alarmas',
    management: 'Gestion',
    odoo: 'Gestion',
    manual: 'Manual',
    legacy: 'Mapa antiguo'
  },
  map: {
    geofence_create_title: 'Nueva geocerca',
    geofence_create_name: 'Por favor indicar el nombre de la geocerca...',
    geofence_created: 'Geocerca criada con éxito!',
    geofence_create_canceled: 'Creación de geocerca cancelada!',
    poi_create_title: 'Nuevo POI',
    poi_create_name: 'Por favor indicar el nombre del POI...',
    poi_created: 'POI criado con éxito!',
    poi_create_canceled: 'Creación de POI cancelada!',
    create_confirm: 'Confirmar',
    create_cancel: 'Cancelar',
    loading: 'Cargando...',
    totalDistance: 'Distancia total',
    poi_click_on_map: 'Clique en el mapa para crear el POI',
    poi_move_on_map: 'Arrastre el marcador a la nueva posición y haga clique en el marcador para guardar el cambio',
    move_poi_title: 'Mover POI',
    confirm_move_poi: 'Quiere mover el POI a esta posición?',
    send_immobilization_command_ok: 'Comando enviado con éxito!',
    line_create_canceled: 'Creación de corredore cancelada!'
  },
  vehicleList: {
    title: 'Vehículos',
    search: 'Buscar...',
    column_name: 'Nombre',
    column_speed: 'Km/h',
    column_lastUpdate: 'Ultima actualización',
    order_by_status: 'Ordenar por Estado',
    order_by_vehicle: 'Ordenar por Vehículo',
    order_by_group: 'Ordenar por Grupo',
    order_by_last_update: 'Ordenar por ultima actualización',
    order_by_fuel_level: 'Ordenar por nivel de combustible',
    order_by: 'Ordenar por...'
  },
  vehicleTable: {
    immobilize: 'Inmovilizar',
    de_immobilize: 'Movilizar',
    send_immobilization: 'Enviar comando de inmovilización para el vehículo',
    send_de_immobilization: 'Enviar comando de movilización para el vehículo',
    immobilization_not_authorized: 'No tiene permisos para ejecutar el comando de inmovilización.',
    all_vehicles: 'Todos',
    moving_vehicles: 'En movimiento',
    idle_vehicles: 'En ralentí',
    stopped_vehicles: 'Detenido',
    disconnected_vehicles: 'Desconocido',
    immo_pending: 'Ya existe un comando de pendente para el vehículo. Por favor aguarde el resultado.'
  },
  vehicleDetail: {
    show_route: 'Ruta',
    follow: 'Seguir',
    unfollow: 'Parar'
  },
  poiTable: {
    showPOIs: 'Mostrar POIs',
    edit_poi: 'Editar',
    delete_poi: 'Borrar',
    hide_geofence: 'Ocultar zona',
    hide_all: 'Ocultar todos',
    show_geofence: 'Mostrar zona',
    show_all: 'Mostrar todos'
  },
  tripsTable: {
    trip: 'Viaje',
    trips: 'Viajes',
    total: 'Totales'
  },
  dashboard: {
    startdate: 'Fecha de inicio',
    enddate: 'Fecha de fin',
    period_lastweek: 'Ultima semana',
    period_lastmonth: 'Ultimo mes',
    period_last3month: 'Ultimos 3 meses'
  },
  navbar: {
    profile: 'Perfil',
    settings: 'Ajustes',
    logout: 'Salir',
    notifications: 'Notificaciones'
  },
  login: {
    newPassword: 'Por favor introduza una nueva contraseña',
    signInWithGoogle: 'Iniciar sesión con Google',
    login_password: 'Contraseña',
    login_confirm_password: 'Confirme la contraseña',
    login_button: 'Iniciar',
    login_password_warn: 'La contraseña no puede tener menos de 6 caracteres',
    login_user_password_invalid: 'usuario o contraseña inválidos.',
    login_user: 'Usuario',
    network_error: 'No se puede establecer la conexión con el servidor.',
    forgotPassword: 'Olvidó su clave?',
    register: 'Nuevo usuario?'
  },
  profile: {
    user_account: 'Usuario',
    user_name: 'Nombre',
    user_email: 'E-mail / usuario',
    user_password: 'Cambiar contraseña',
    user_phone: 'Teléfono',
    user_language: 'Idioma',
    user_timezone: 'Huso horario',
    user_update_button: 'Guardar',
    user_updated: 'La información del usuario ha sido actualizada.',
    user_name_required: 'El campo de nombre es obligatorio',
    user_email_required: 'Por favor indicar un e-mail válido.',
    email_required: 'El campo de e-mail es obligatorio.',
    user_password_lengh: 'La contraseña no puede tener menos de 8 caracteres',
    user_reports: 'Informes por email',
    daily_reports: 'Recibir diariamente',
    weekly_reports: 'Recibir semanalmente',
    monthly_reports: 'Recibir mensalmente',
    continue: 'Continuar?',
    unsaved_changes: 'Existen cambios que no fueron guardados',
    inactive_vehicles_email: 'Alarma de inactividade de los equipos',
    inactive_vehicles_email_tooltip: 'Será enviado un email de alarma indicando los equipos que no comunican hace más de 48 horas',
    daily_reports_tooltip: 'Email enviado diariamente con informacción de la actividad de los vehículos',
    weekly_reports_tooltip: 'Email enviado semanalmente con informacción de la actividad de los vehículos',
    monthly_reports_tooltip: 'Email enviado mensalmente con informaccion da la actividad de los vehículos',
    user_type_admin: 'Administrador',
    user_type_manager: 'Gerente',
    user_type_operator: 'Operador'
  },
  settings: {
    alert_alarm: 'Todos',
    alert_alarmEngineOn: 'Conducción excesiva',
    alert_alarmEngineOff: 'Parada larga',
    vehicle_icon_towtruck: 'Gondola',
    vehicle_icon_mixer: 'Hormigonera',
    vehicle_icon_cistern: 'Cisterna',
    alert_warning_type: 'Este tipo de alerta necesita un equipo compatible',
    driver_add_title: 'Añadir Conductor',
    driver_password_changed: 'Contraseña cambiada con éxito',
    drivers_export_excel: 'Exportación de Conductores',
    drivers_create_new_password: '¿Desea generar Excel con la información de la contraseña del conductor? Si es así, se generarán nuevas contraseñas para todos los conductores.',
    max_digital_port_open_time: 'Tiempo máximo con el sensor digital conectado (segundos)',
    rfid_start: 'Inicio',
    rfid_end: 'Fim',
    commands: 'Comandos',
    modules: 'Módulos',
    alert_temperature: 'Temperatura',
    min_temperature: 'Temperatura mínima',
    max_temperature: 'Temperatura máxima',
    calibrate_temperature: 'Calibrar temperatura',
    vehicle_form_category: 'Icono',
    vehicle_icon_digger: 'Excavadora',
    vehicle_icon_roadroller: 'Cilindro',
    vehicle_icon_dumpingtruck: 'Camión Volquete',
    vehicle_associated_driver: 'Conductor',
    showFullDate: 'Mostrar fecha/hora de la última comunicación',
    by_vehicle: 'Por Vehículo',
    by_maintenance: 'Por Mantenimiento',
    next_maintenance: 'Próximo Mantenimiento',
    vehicle: 'Vehículo',
    remaining_kms: 'Kms Restantes',
    remaining_hours: 'Horas Restantes',
    createTempPassword: 'Cambiar contraseña',
    maintenance: 'Mantenimiento',
    maintenance_start: 'Inicio',
    maintenance_period: 'Periodo',
    maintenance_type: 'Tipo',
    maintenance_type_totalDistance: 'Kms',
    maintenance_type_period: 'Periodo',
    maintenance_type_hours: 'Horas',
    maintenance_start_date: 'Fecha de Inicio',
    maintenance_months: 'Meses',
    maintenance_hours: 'Horas',
    maintenance_add: 'Crear Mantenimiento',
    maintenance_edit: 'Editar Mantenimiento',
    maintenance_name: 'Nombre',
    maintenance_created: 'Mantenimiento creado con éxito!',
    maintenance_updated: 'Mantenimiento actualizado con éxito!',
    maintenance_delete_info: 'Desea borrar el mantenimiento  ',
    maintenance_delete_title: 'Borrar Mantenimiento',
    maintenance_delete_not_allowed: 'No tienes permiso para borrar mantenimientos.',
    maintenance_deleted: 'Mantenimiento ha sido apagado.',
    labelColor: 'Color de la etiqueta',
    labelTextSize: 'Tamaño del texto',
    user_form_manage_devices: 'Permitir cambiar los datos de los vehículos',
    vehicle_route_color: 'Color de la ruta',
    icons3d: 'Íconos 3D',
    showLabels: 'Mostrar nombre del vehículo',
    showPOIsLabels: 'Mostrar nombre de POI',
    reducePoiSize: 'Reducir el tamaño de Poi al alejar el zoom',
    search: 'Buscar...',
    empty_list: 'Nada encontrado',
    refresh: 'Recargar la aplicación',
    version: 'Versión',
    connected: 'Conectado',
    disconnected: 'Desconectado',
    connection: 'Conexión',
    map: 'Mapa',
    about: 'Información',
    logout: 'Salir',
    vehicles: 'Vehículos',
    pois: 'POIs',
    title: 'Configuraciones',
    route_history: 'Histórico de Ruta',
    route_match: 'Ruta en la calle',
    view_speed_alerts: 'Mostrar alarmas de velocidad',
    use_route_speed_limit: 'Usar límites de velocidad de carreteras',
    use_vehicle_speed_limit: 'Usar limite de velocidad del vehículo',
    max_speed_threshold: 'Tolerância Máxima en Km/h',
    select_all: 'Seleccionar Todos',
    deselect_all: 'Retirar Todos',
    form_cancel: 'Cancelar',
    form_confirm: 'Confirmar',
    form_save: 'Guardar',
    transfer_selected: 'Seleccionado',
    configuration: 'Configuración',
    time: 'Horário',
    emails: 'Otros Emails',
    other_emails: 'Otros Emails (separados por coma)',
    add_alert: 'Añadir Alarma',
    alerts: 'Alarmas',
    alert_door: 'Puerta abierta',
    alert_door1: 'Puerta abierta',
    alerts_type: 'Tipo',
    alerts_notificators: 'Vias',
    alert_unidentifiedDriver: 'Conductor No Identificado',
    alert_ignitionOff: 'Motor apagado',
    alert_ignitionOn: 'Motor encendido',
    alert_geofenceEnter: 'Entrada en geocerca',
    alert_geofenceExit: 'Salida de geocerca',
    alert_deviceOverspeed: 'Exceso de velocidad',
    alert_sos: 'SOS',
    alert_gpsAntennaCut: 'GPS Antena',
    alert_tow: 'Remolque',
    alert_highRpm: 'RPM Alto',
    alert_hardAcceleration: 'Aceleracción Brusca',
    alert_hardBraking: 'Frenada Brusca',
    alert_hardCornering: 'Curva cerrada',
    alert_powerCut: 'Bateria desconectada',
    alert_shock: 'Shock',
    alert_powerOn: 'Power Take-Off',
    alert_deviceFuelDrop: 'Perdida de Combustible',
    alert_driverChanged: 'Conductor identificado',
    alert_breakdown: 'Desglose',
    alert_maintenance: 'Mantenimiento',
    alert_idle: 'Ralenti',
    alert_inactivity: 'Inactividade de los equipos',
    alert_deleted: 'Alarma ha sido apagado.',
    alert_delete_info: 'Desea borrar la alarma de ',
    alert_delete_title: 'Borrar Alarma',
    alert_edit_confirm: 'Confirmar',
    alert_edit_cancel: 'Cancelar',
    alert_created: 'Alarma creada con éxito!',
    alert_updated: 'Alarma actualizada con éxito!',
    alert_add: 'Crear Alarma',
    alert_edit: 'Editar Alarma',
    alert_delete: 'Borrar Alarma',
    alert_overspeed_warning: 'Vehículo sin velocidad máxima establecida',
    alert_geofences_warning: 'Vehículo sin geocercas asociadas',
    alert_form_type: 'Tipo:',
    alert_form_type_placeholder: 'Seleccionar tipo de alarma',
    alert_form_vehicles: 'Vehículos:',
    alert_form_geofences: 'Geocercas:',
    alert_form_pois: 'POIs:',
    alert_form_all_vehicles: 'Todos los vehículos',
    alert_form_vehicles_placeholder: 'Seleccione vehículos',
    alert_form_notificator_web: 'Notificación',
    alert_form_notificator_email: 'E-mail',
    alert_form_notificator_sms: 'SMS',
    alert_form_confirm: 'Guardar',
    alert_form_cancel: 'Cancelar',
    alert_form_geofences_placeholder: 'Seleccione geocercas',
    alert_form_pois_placeholder: 'Seleccione POIs',
    alert_form_linegeofences_placeholder: 'Seleccione corredores',
    alert_form_select_all: 'Seleccionar Todos',
    alert_form_deselect_all: 'Remover Todos',
    alert_form_ignitionoff_max_duration: 'Duración Máxima (Minutos)',
    alert_form_notificator_select_on: 'Seleccione al menos uno',
    alert_form_notificators: 'Vias',
    vehicle_edit: 'Editar Vehículo',
    vehicle_name: 'Nombre',
    vehicle_form_group: 'Grupo',
    vehicle_form_groups_placeholder: 'Seleccionar Grupo',
    vehicle_licenseplate: 'Matrícula',
    vehicle_model: 'Marca / Modelo',
    vehicle_form_total_kms: 'Total de Kms',
    vehicle_form_fuel_tank_capacity: 'Capacidad depósito combustible (L)',
    vehicle_speed_limit: 'Límite (Km/h)',
    vehicle_form_name: 'Nombre',
    vehicle_form_model: 'Modelo',
    vehicle_form_speed_limit: 'Límite de Velocidad (Km/h)',
    vehicle_form_speed_minimal_duration: 'Duración mínima (minutos)',
    vehicle_form_by_date: 'Por fecha',
    vehicle_form_apply_to_all: 'Aplicar configuración en todos los vehículos.',
    vehicle_kms_form: 'Editar Kms del vehículo',
    vehicle_kms_form_date: 'Fecha',
    vehicle_kms_form_total_kms: 'Kms totales',
    vehicle_total_hours: 'Horas totales',
    vehicle_kms_form_date_placeholder: 'Seleccione fecha y hora',
    vehicle_kms: 'Kms',
    vehicle_lastposition: 'Última posición',
    vehicle_lastposition_address: 'Dirección',
    vehicle_notes: 'Notas',
    vehicle_gsm: 'GSM',
    vehicle_kms_traveled: 'Kms percorridos',
    vehicle_kms_current: 'Kms actuales',
    vehicle_hours_traveled: 'Horas percorridos',
    vehicle_hours_current: 'Horas actuales',
    vehicle_updated: 'Vehículo actualizado con suceso!',
    vehicle_kms_updated: 'Kms del vehículo actualizados con éxito!',
    vehicle_geofences_updated: 'Geocercas asociadas al vehículo actualizadas con éxito!',
    vehicle_group: 'Grupo',
    vehicle_icon_car: 'Vehículo',
    vehicle_icon_truck: 'Camión',
    vehicle_icon_truck2: 'Camión 2',
    vehicle_icon_trailer: 'Remolque',
    vehicle_icon_van: 'Van',
    vehicle_icon_default: 'Normal',
    vehicle_icon_bicycle: 'Bicicleta',
    vehicle_icon_person: 'Persona',
    vehicle_icon_arrow: 'Flecha',
    vehicle_icon_bus: 'BUS',
    vehicle_icon_tractor: 'Tractor',
    vehicle_icon_helicopter: 'Helicóptero',
    vehicle_icon_motorcycle: 'Moto',
    vehicle_icon_ambulance: 'Ambulancia',
    vehicle_icon_boat: 'Barco',
    vehicle_icon_pickup: 'Pickup',
    vehicle_edit_kms: 'Editar Kms',
    vehicle_edit_not_allowed: 'No tiene permisos para editar el vehículo',
    vehicle_without_position: 'Vehículo sin posición',
    vehicle_associate_geofences: 'Asociar Geocercas',
    vehicle_form_category_placeholder: 'Seleccionar Icono',
    speed_limit: 'Límite de Velocidad',
    unidentified_driver_duration: 'Duración mínima (minutos)',
    geofences: 'Zonas',
    geofences_title: 'Geocercas',
    geofence_name: 'Nombre',
    geofence_description: 'Descripción',
    geofence_speed_Limit: 'Límite de Velocidad (Km/h)',
    geofences_type_geofences: 'Geocerca:',
    geofences_type_linegeofences: 'Líneas:',
    linegeofences: 'Líneas',
    geofences_type_pois: 'POIs:',
    geofence_icon_square: 'Cuadrado',
    geofence_icon_triangle: 'Triángulo',
    geofence_icon_airport: 'Airport',
    geofence_icon_aquarium: 'Aquarium',
    geofence_icon_attraction: 'Viewpoint',
    geofence_icon_barrier: 'Barrier',
    geofence_icon_building_alt1: 'Tall Building',
    geofence_icon_building: 'Building',
    geofence_icon_car_rental: 'Car Rental',
    geofence_icon_car_repair: 'Car Repair',
    geofence_icon_castle: 'Castle',
    geofence_icon_cemetery: 'Cemetery',
    geofence_icon_charging_station: 'Charging Station',
    geofence_icon_circle: 'Circle',
    geofence_icon_city: 'City',
    geofence_icon_embassy: 'Flag',
    geofence_icon_fuel: 'Estación de Servício',
    geofence_icon_home: 'Home',
    geofence_icon_industry: 'Industry',
    geofence_icon_information: 'Information',
    geofence_icon_marker: 'Marker',
    geofence_icon_marker_stroked: 'Marker',
    geofence_icon_parking: 'Parking',
    geofence_icon_parking_garage: 'Parking Garage',
    geofence_icon_ranger_station: 'Fire Station',
    geofence_icon_recycling: 'Recycling',
    geofence_icon_residential_community: 'Residential',
    geofence_icon_star: 'Star',
    geofence_icon_town: 'Town',
    geofence_icon_town_hall: 'Town Hall',
    geofence_icon_village: 'Village',
    geofence_icon_warehouse: 'Warehouse',
    geofence_icon_waste_basket: 'Waste Basket',
    geofence_icon_windmill: 'Windmill',
    geofence_edit_title: 'Editar Zona',
    geofence_form_name: 'Nombre',
    geofence_form_description: 'Descripción',
    geofence_form_icon: 'Icon',
    geofence_form_color: 'Color',
    geofence_form_options: 'Opciones',
    geofence_form_speedlimit: 'Límite de Velocidad (Km/h)',
    geofence_form_distance: 'Distancia máxima',
    geofence_form_distance_apply_to_all: 'Aplicar la distancia a todos los POIs.',
    geofence_form_color_apply_to_all: 'Aplicar el color a todos los POIs.',
    zone: 'Zonas',
    groups: 'Grupos',
    group_edit: 'Editar Grupo',
    group_add: 'Crear Grupo',
    group_name: 'Nombre',
    group_description: 'Descripción',
    group_form_name: 'Nombre',
    group_form_description: 'Descripción',
    group_updated: 'Grupo actualizado con éxito!',
    group_deleted: 'EL Grupo ha sido borrado.',
    group_delete_info: 'Desea borrar el grupo',
    group_delete_title: 'Borrar Grupo',
    group_edit_confirm: 'Confirmar',
    group_edit_cancel: 'Cancelar',
    group_created: 'Grupo creado con éxito!',
    group_select_vehicles_placeholder: 'Seleccionar Vehículos',
    group_select_drivers_placeholder: 'Seleccionar Conductores',
    group_select_geofences_placeholder: 'Seleccionar Geocercas',
    group_select_pois_placeholder: 'Seleccionar POIs',
    group_select_linegeofences_placeholder: 'Seleccionar corredores',
    group_add_not_allowed: 'No tienes permiso para crear grupos.',
    group_edit_not_allowed: 'No tienes permiso para editar grupos.',
    group_delete_not_allowed: 'No tienes permiso para borrar grupos.',
    drivers: 'Conductores',
    driver_name: 'Nombre',
    driver_email: 'E-Mail',
    driver_phone: 'Movil',
    driver_notes: 'Notas',
    driver_add: 'Crear conductor',
    driver_edit: 'Editar',
    driver_edit_title: 'Editar conductor',
    driver_delete: 'Borrar',
    driver_form_cancel: 'Cancelar',
    driver_form_confirm: 'Confirmar',
    driver_deleted: 'El conductor fué borrado',
    driver_delete_info: 'Desea borrar el conductor ',
    driver_delete_title: 'Borrar conductor',
    driver_created: 'Conductor creado com éxito!',
    driver_updated: 'conductor actualizado con éxito!',
    driver_add_not_allowed: 'No tienes permiso para crear conductores.',
    driver_edit_not_allowed: 'No tienes permiso para editar conductores.',
    driver_delete_not_allowed: 'No tienes permiso para borrar conductores.',
    driver_uniqueid_create: 'Crear',
    driver_uniqueid_create_label: 'Crear identificador',
    driver: 'Conductor',
    reports: 'Informes',
    automatic_reports: 'Informes automáticos',
    activate_automatic_trip_report: 'Informe de viajes - Habilitar informes automáticos',
    activate_automatic_location_report: 'Informe de posiciones - Habilitar informes automáticos',
    activate_automatic_zone_report: 'Informe de zonas - Habilitar informes automáticos',
    activate_automatic_speeding_report: 'Informe de excesos de velocidad - Habilitar informes automáticos',
    activate_automatic_refueling_report: 'Informe de Cargas - Habilitar informes automáticos',
    activate_automatic_activity_report: 'Informe de Actividade - Habilitar informes automáticos',
    activate_automatic_events_report: 'Informe de Eventos - Habilitar informes automáticos',
    activate_automatic_fueldrop_report: 'Fuel drop report - Habilitar informes automáticos',
    activate_automatic_kms_report: 'Informe de Kms - Habilitar informes automáticos',
    report_periodicity_daily: 'Diariamente',
    report_periodicity_weekly: 'Semanalmente',
    report_periodicity_monthly: 'Mensualmente',
    report_by_group: 'Reciba un e-mail para cada grupo de vehículos',
    uniqueid_duplicated: 'RFID Key duplicada!',
    pois_title: 'POIs',
    add: 'Crear',
    edit: 'Editar',
    delete: 'Borrar',
    name_required: 'El campo nombre és obligatório',
    email_format_invalid: 'Por favor indique un e-mail válido',
    email_or_phone_required: 'Por lo menos uno de estos campos deverá ser llenado con un valor valido.',
    users: 'Usuarios',
    user_edit: 'Editar usuario',
    user_add: 'Crear usuario',
    user_name: 'Nombre',
    user_email: 'E-mail',
    user_phone: 'Teléfono',
    user_language: 'Idioma',
    user_timezone: 'Huso horario',
    user_type: 'Perfil',
    user_password: 'Contraseña',
    user_form_type_placeholder: 'Seleccione en perfil',
    user_deleted: 'Usuario borrado.',
    user_delete_info: 'Deséa borrar el usuario',
    user_delete_title: 'Borrar usuario',
    user_created: 'Usuario creado con éxito!',
    user_updated: 'Usuario actualizado con éxito!',
    user_create_not_allowed: 'No tienes permissos para crear usuarios',
    user_edit_not_allowed: 'No tienes permissos para editar usuarios',
    user_duplicated_entry: 'El usuario ya existe.',
    temporary_links: 'Accesos Temporales',
    expiration_date: 'Fecha Limite',
    user_temporary_link: 'Acceso',
    temporary_link_add: 'Crear Acceso Temporal',
    temporary_link_edit: 'Editar Acceso Temporal',
    temporary_link_created: 'Aceso temporal creado con éxito!',
    temporary_link_create_not_allowed: 'No tiene permisos para crear acesos temporários',
    temporary_link_deleted: 'El aceso fué borrado',
    temporary_link_delete_info: 'Desea borrar el acesso temporal',
    temporary_link_delete_title: 'Borrar Acesso Temporário',
    full: 'Completo',
    partial: 'Parcial',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miercoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    sunday: 'Domingo',
    selectStartTime: 'Seleccionar hora de inicio',
    selectEndTime: 'Seleccionar hora de fín'
  },
  geofence: {
    showGeofences: 'Mostrar Geocercas',
    geofence_name: 'Nombre',
    geofence_edit: 'Editar',
    geofence_delete: 'Borrar',
    geofence_deleted: 'La geocerca ha sido borrada',
    geofence_delete_info: '¿Deséas borrar la geocerca ',
    geofence_delete_title: 'Borrar Geocerca',
    geofence_edit_title: 'Editar Geocerca',
    geofence_edit_name: 'Por favor indique el nombre de la geocerca...',
    geofence_edit_confirm: 'Confirmar',
    geofence_edit_cancel: 'Cancelar',
    geofence_edit_canceled: 'Editar cancelado',
    geofence_edited: 'Geocerca editada con éxito!',
    poi_delete_info: 'Deséa borrar el POI ',
    poi_delete_title: 'Borrar POI',
    poi_edited: 'POI editado con éxito!',
    poi_deleted: 'POI ha sido borrado',
    poi_edit_title: 'Editar POI',
    poi_edit_name: 'Por favor indicar el nombre del POI...',
    linegeofence_delete_info: 'Deséa borrar el corredor ',
    linegeofence_delete_title: 'Borrar Corredor',
    linegeofence_edited: 'Corredor editado com éxito!',
    linegeofence_deleted: 'Corredor fué borrado',
    linegeofence_edit_title: 'Editar Corredor',
    linegeofence_edit_name: 'Por favor indique el nombre del corredor...',
    searchGeofence: 'Buscar Geocerca',
    edit_geofence: 'Editar',
    delete_geofence: 'Borrar'
  },
  report: {
    selector_search: 'Buscar',
    select_vehicles: 'Seleccionados',
    select_vehicles_placeholder: 'Vehículos',
    select_groups: 'Seleccionados',
    select_groups_placeholder: 'Grupos',
    select_geofences: 'Seleccionados',
    select_geofences_placeholder: 'Geocercas',
    select_period: 'Seleccione periodo',
    date_start: 'Fecha de inicio',
    date_end: 'Fecha de fin',
    generate_report: 'Generar informe',
    period: 'Periodo',
    select_all: 'Todos',
    validate_period: 'Por favor seleccione un periodo'
  },
  layout: {
    deviceOnline: 'Dispositivo en línea',
    deviceMoving: 'Dispositivo en movimiento',
    deviceStopped: 'Dispositivo detenido',
    ignitionOff: 'Motor apagado',
    ignitionOn: 'Motor encendido',
    deviceOverspeed: 'Excesos de velocidad',
    geofenceEnter: 'Entrada en geocerca',
    geofenceExit: 'Salida de geocerca',
    newVersion: 'Una nueva versión de esta aplicación está disponible. Haga clic aquí para actualizar.'
  },
  alerts: {
    title: 'Alarmas',
    get_alerts: 'Obtener Notificaciones'
  }
}
