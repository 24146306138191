import { serverBus } from '@/main'
import { traccar } from '@/api/traccar-api'
import * as event from '../../events'
import Vue from 'vue'
import * as notifications from '../../utils/notifications'

const state = {
  dataLoaded: false,
  devicesLoaded: false,
  notificationPermissionsLoaded: false,
  historyMode: false,
  events: [],
  stiLoaded: false,
  isPlaying: false,
  trips: [],
  stops: [],
  portrait: true,
  loading: false,
  loadingEvents: false,
  summary: [],
  totalDistance: 0,
  search: '',
  routeFetched: false,
  initialized: false,
  percentage: 0
}

const mutations = {
  SET_NOTIFICATION_PERMISSIONS_LOADED(state, value) {
    state.notificationPermissionsLoaded = value
  },
  SET_PERCENTAGE(state, value) {
    state.percentage = value
  },
  SET_INITIALIZED(state, value) {
    state.initialized = value
  },
  SET_ROUTE_FETCHED(state, value) {
    state.routeFetched = value
  },
  SET_SEARCH(state, value) {
    state.search = value
  },
  SET_TOTAL_DISTANCE(state, value) {
    state.totalDistance = value
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_LOADING_EVENTS(state, value) {
    state.loadingEvents = value
  },
  SET_PORTRAIT(state, value) {
    state.portrait = value
  },
  SET_TRIPS(state, trips) {
    state.trips = trips
  },
  SET_STOPS(state, stops) {
    state.stops = stops
  },
  TOGGLE_PLAY(state) {
    state.isPlaying = !state.isPlaying
  },
  SET_PLAYING(state, value) {
    state.isPlaying = value
  },
  SET_EVENTS(state, events) {
    Vue.$log.debug('SET_EVENTS:', events.length)
    state.events = events
    serverBus.$emit(event.eventsLoaded)
  },
  ADD_EVENTS(state, events) {
    state.events = events.concat(state.events)
  },
  SET_DATA_LOADED(state, value = true) {
    state.dataLoaded = value
  },
  SET_DEVICES_LOADED(state, value = true) {
    state.devicesLoaded = value
  },
  TOGGLE_HISTORY_MODE: (state) => {
    state.historyMode = !state.historyMode
    if (state.historyMode) { state.routeFetched = false }
    state.trips.splice(0)
    serverBus.$emit(event.showRoutesChanged)
    setTimeout(() => serverBus.$emit(event.mapShow), 500)
  }
}

const actions = {
  setLoading({ commit }, loading) {
    commit('SET_LOADING', loading)
  },
  setPortrait({ commit }, value) {
    commit('SET_PORTRAIT', value)
  },
  setTrips({ commit }, trips) {
    commit('SET_TRIPS', trips)
  },
  setStops({ commit }, stops) {
    commit('SET_STOPS', stops)
  },
  setPlaying({ commit }, value) {
    commit('SET_PLAYING', value)
  },
  togglePlaying({ commit }) {
    commit('TOGGLE_PLAY')
  },
  toggleHistoryMode(context) {
    context.commit('TOGGLE_HISTORY_MODE')
    context.dispatch('setPlaying', false)
  },
  setDataLoaded({ commit }) {
    commit('SET_DATA_LOADED')
  },
  setDevicesLoaded({ commit }) {
    commit('SET_DEVICES_LOADED')
  },
  async loadNotificationPermissions({ commit, dispatch }) {
    commit('user/SET_LOAD_NOTIFICATIONS_BY_DEVICE', true, { root: true })
    return dispatch('user/fetchAlerts', null, { root: true })
  },
  async fetchEvents({ commit, rootGetters, state, dispatch }, { start, end, types }) {
    if (!state.notificationPermissionsLoaded) {
      await dispatch('loadNotificationPermissions')
      commit('SET_NOTIFICATION_PERMISSIONS_LOADED', true)
    }
    const eventsReceived = []
    if (types.length) {
      const r = await traccar.report_events(
        start,
        end,
        rootGetters.devices.map(d => d.id),
        types
      )

      for (const response of r) {
        for (const e of response.data) {
          if (await notifications.filterEvent(e, rootGetters.alerts)) {
            eventsReceived.push(e)
          }
        }
      }
    }
    commit('SET_EVENTS', notifications.convertEvents(eventsReceived, false))
  },
  addEvents({ commit }, events) {
    Vue.$log.debug('Commit addevent')
    commit('ADD_EVENTS', events)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

