import { vm, newServiceWorker, sharedData } from '@/main'
import Vue from 'vue'
import { partnerData } from './partner'
import { getFuelPercentage } from '@/utils/positions'
import { reducePositions } from '@/utils/index'

export function getDate(dateString) {
  return Vue.moment(dateString)
}

export function formatDate(v) {
  v = sharedData.getPositions().findIndex(x => Vue.moment(x.fixTime).unix() === v)
  const fixTime = sharedData.getPositions()[v] ? sharedData.getPositions()[v].fixTime : new Date()
  const speed = sharedData.getPositions()[v] ? sharedData.getPositions()[v].speed : ''
  let result = getDate(fixTime).format('YYYY-MM-DD HH:mm:ss')
  if (speed && speed > 0) {
    result += (' ' + ~~(speed * 1.852) + 'km/h')
  }
  return result
}

export function formatAddress(v) {
  return (sharedData.getPositions() && sharedData.getPositions()[v]) ? sharedData.getPositions()[v].address : ''
}

export function calculateTimeHHMM(idleSeconds) {
  if (idleSeconds < 0) return '00:00'

  const idleHours = String(Math.floor(idleSeconds / 3600)).padStart(2, '0')
  idleSeconds %= 3600
  const idleMinutes = String(Math.floor(idleSeconds / 60)).padStart(2, '0')
  return idleHours + ':' + idleMinutes
}

export function calculateTimeHHMMSS(idleSeconds) {
  const idleHours = String(Math.floor(idleSeconds / 3600)).padStart(2, '0')
  idleSeconds %= 3600
  const idleMinutes = String(Math.floor(idleSeconds / 60)).padStart(2, '0')
  idleSeconds %= 60
  return idleHours + ':' + idleMinutes + ':' + String(idleSeconds).padStart(2, '0')
}

export function filterPositions(positions) {
  positions.sort((a, b) => new Date(a.fixTime) - new Date(b.fixTime))
  const firstPos = positions.findIndex(position => position.attributes.ignition || position.attributes.motion)
  if (firstPos > 0) {
    Vue.$log.debug('slicing positions at ', firstPos)
    positions = positions.slice(firstPos)
  }
  return reducePositions(positions.filter(filterPosition))
}

function filterPosition(p) {
  if (p.valid === false) {
    if (p.protocol === 'osmand' && p.attributes.event === 12) {
      return true
    }
    return p.attributes.ignition || p.attributes.motion
  }
  if (p.protocol === 'osmand') {
    return !(p.attributes.event >= 200 || p.attributes.event === 30)
  }
  return true
}

export function reload() {
  if (newServiceWorker) {
    Vue.$log.info(newServiceWorker, 'skipWaiting!')
    newServiceWorker.postMessage({ action: 'skipWaiting' })
    window.location.reload()
  } else {
    Vue.$log.error(`this shouldn't happen`)
  }
  Vue.$log.info('bye bye...')
}

export function checkForUpdates() {
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      if (registrations.length === 0) {
        Vue.$log.warn('no service worker registrations... thats not good...')
      }
      for (const reg of registrations) {
        reg.update().then(() => Vue.$log.warn('done checking for updates...')).catch(e => console.error(e))
      }
    })
  } else {
    Vue.$log.warn('no serviceWorker, weird browser...')
  }
}

export function appOffline() {
  return !vm.$store.state.socket.isConnected || !vm.$store.state.socket.connectionOk
}

export function getDeviceColor(state) {
  switch (state) {
    case 'Disconnected': return 'gray'
    case 'Moving': return 'green'
    case 'Idle': return 'yellow'
    case 'Stopped': return 'red'
  }
}

const minDaysForDisconnected = partnerData().minDaysForDisconnected || 5
export function getDeviceState(position) {
  if (!position || position.fixDays > minDaysForDisconnected || (new Date() - new Date(position.fixTime)) / (1000 * 60 * 60 * 24) > minDaysForDisconnected) {
    return 'Disconnected'
  } else if (position.speed >= 4 && position.attributes.ignition !== false) {
    return 'Moving'
  } else if (!position.attributes.ignition) {
    return 'Stopped'
  } return 'Idle'
}

export function calculateFuelLevel(adc1CacheValues, position, lastPosition, device) {
  if ('fuel' in position.attributes) {
    // Calculate FuelLevel
    if (position.protocol === 'teltonika') {
      position.fuelLevel = getFuelPercentage(device, position)
    } else if (device.attributes.xpert) {
      // DEVICE WITH XPERT
      position.fuelLevel = Math.round(position.attributes.fuel)
    } else {
      if ('fuel_low_threshold' in device.attributes &&
        'fuel_high_threshold' in device.attributes) {
        // DEVICE WITH FUEL SENSOR
        if (adc1CacheValues.length === 5) {
          adc1CacheValues.splice(0, 1)
        }

        adc1CacheValues.push(position.attributes.fuel)

        const adc1CalculatedValue = (adc1CacheValues.reduce((total, value) => total + value, 0)) / adc1CacheValues.length
        const level = Math.round(((device.attributes.fuel_low_threshold - adc1CalculatedValue) / (device.attributes.fuel_low_threshold - device.attributes.fuel_high_threshold)) * 100)

        if (level >= 0 && level <= 100) {
          position.fuelLevel = level
          position.adc1CacheValues = adc1CacheValues
        } else if (lastPosition) {
          position.fuelLevel = lastPosition.fuelLevel
          position.adc1CacheValues = adc1CacheValues
        }
      } else {
        position.fuelLevel = position.attributes.fuel
      }
    }
  }
}

export function chunkArray(originalArray, chunkSize) {
  const results = []
  const newArray = [...originalArray]

  while (newArray.length) {
    results.push(newArray.splice(0, chunkSize))
  }

  return results
}

export function getEventDescription(event) {
  const result = (vm.$t('settings.alert_' + event.type) !== 'settings.alert_' + event.type)
    ? vm.$t('settings.alert_' + event.type)
    : event.type
  const info = event.info || (event.attributes && event.attributes.info)
  return result + (info ? ` ${info}` : '')
}
