import Vue from 'vue'
import axios from 'axios'
import { pinmeapi } from '@/api/pinme'
import store from '@/store'

export async function routeMatch(devices, rows) {
  Vue.$log.debug(devices, rows)
  try {
    return pinmeapi.getSpeedingEvents({
      devices,
      from: rows[0].fixTime,
      to: rows.slice(-1)[0].fixTime,
      userData: {
        user: store.getters.user,
        geofences: [],
        roadSpeedLimits: true
      }
    }).then(d => d.data.events)
  } catch (e) {
    console.error(e)
    return []
  }
}

export async function getSpeedLimitByPosition(position) {
  const hereSpeedLimits = process.env.HERE_API_KEY
  const url = `https://fleet.ls.hereapi.com/2/calculateroute.json?apiKey=${
    hereSpeedLimits}&routeMatch=1&mode=fastest;car;traffic:disabled&waypoint0=${
    position.latitude},${position.longitude}&attributes=SPEED_LIMITS_FCn(*)`
  const r = await axios.get(url).then(r => r.data).catch(e => console.error(url, e.message, e.response && e.response.data))
  const links = r && r.response && r.response.route && r.response.route[0] && r.response.route[0].leg[0].link
  if (links) {
    const speeds = []
    links.map(x => x.attributes).filter(x => x).forEach(a => {
      speeds.push(parseInt(a.SPEED_LIMITS_FCN[0].FROM_REF_SPEED_LIMIT))
      speeds.push(parseInt(a.SPEED_LIMITS_FCN[0].TO_REF_SPEED_LIMIT))
    })
    return speeds.length ? Math.max(...speeds) : Number.MAX_SAFE_INTEGER
  } else {
    console.log('no links on', position)
  }
}
