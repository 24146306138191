export function svgToPng(svg, callback) {
  const url = getSvgUrl(svg)
  svgUrlToPng(url, (imgData) => {
    callback(imgData)
    URL.revokeObjectURL(url)
  })
}
function getSvgUrl(svg) {
  return URL.createObjectURL(new Blob([svg], { type: 'image/svg+xml' }))
}
function svgUrlToPng(svgUrl, callback) {
  const svgImage = document.createElement('img')
  // imgPreview.style.position = 'absolute';
  // imgPreview.style.top = '-9999px';
  document.body.appendChild(svgImage)
  svgImage.onload = function() {
    const canvas = document.createElement('canvas')
    canvas.width = svgImage.clientWidth
    canvas.height = svgImage.clientHeight
    const canvasCtx = canvas.getContext('2d')
    canvasCtx.drawImage(svgImage, 0, 0)
    const imgData = canvas.toDataURL('image/png')
    callback(imgData)
    // document.body.removeChild(imgPreview);
  }
  svgImage.src = svgUrl
}
export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result ? [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16)] : null
}
