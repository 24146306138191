export default {
  'Google Hybrid': 'Google Satellite',
  Yes: 'Yes',
  No: 'No',
  shareScreen: 'Partilhar ecrã',
  Copiado: 'Copied',
  copyClipboardMessage: 'copied to clipboard.',
  app: {
    connectionLost: 'Conexion terminada',
    reconnect: 'Desea restabelecer la conexión con el servidor?'
  },
  route: {
    map: 'Map',
    dashboard: 'Dashboard',
    reports: 'Reports',
    report: 'Report',
    report_trip_title: 'Trip Report',
    report_location_title: 'Location Report',
    report_zone_crossing: 'Zone Report',
    report_speeding: 'Speeding Report',
    report_refueling: 'Refueling Report',
    report_fueldrop: 'Fuel Drop Report',
    report_speeding_beta: 'Relatório de Excessos de Velocidade BETA',
    report_tolls: 'Relatório de Portagens',
    report_timerange: 'Informe uso fuera de horario',
    report_events: 'Events Report',
    report_activity: 'Activity Report',
    report_kms: 'Kms Report',
    report_fuelconsumption: 'Fuel Consumption Report',
    customreport_vistawaste_activity_title: 'Relatório de Actividade Vista Waste',
    settings: 'Settings',
    duration: 'Duration',
    nodata: 'Não existem dados para o período seleccionado.',
    alerts: 'Alerts',
    custom_reports: 'Relatórios Custom',
    maintenance: 'Módulo de Manutenção',
    scheduler: 'Automatic Reports',
    management: 'Management',
    odoo: 'Management',
    manual: 'Manual',
    tacho: 'Tachograph'
  },
  map: {
    geofence_create_error: 'Error creating geofence',
    poi_create_error: 'Error creating POI',
    geofence_create_title: 'New geofence',
    geofence_create_name: 'Please enter the name of this geofence...',
    geofence_created: 'Geofence created sucessfully!',
    geofence_create_canceled: 'Input canceled',
    poi_create_title: 'New POI',
    poi_create_name: 'Please enter the name of this POI...',
    poi_created: 'POI created sucessfully!',
    line_create_name: 'Por favor indique o nome da rota...',
    line_create_title: 'Nova Rota',
    line_created: 'Rota criada com sucesso!',
    poi_create_canceled: 'Input canceled',
    create_confirm: 'OK',
    create_cancel: 'Cancel',
    loading: 'Loading',
    totalDistance: 'Total Distance',
    poi_click_on_map: 'Click the point on the map where you want to create the POI',
    poi_move_on_map: 'Arraste o marcador para o novo local e clique em cima do marcador para gravar a alteração',
    move_poi_title: 'Mover POI',
    confirm_move_poi: 'Deseja mover o POI para este local?',
    send_immobilization_command_ok: 'Command sent successfully!',
    line_create_canceled: 'Line creation canceled!'
  },
  vehicleList: {
    title: 'Vehicle',
    search: 'Search...',
    empty_list: 'Nothing found',
    column_name: 'Name',
    column_speed: 'Km/h',
    column_lastUpdate: 'Last update',
    order_by_status: 'Order by State',
    order_by_vehicle: 'Order by Vehicle',
    order_by_group: 'Order by Group',
    order_by_last_update: 'Order by Last Update',
    order_by_fuel_level: 'Order by Fuel Level',
    order_by: 'order by...'
  },
  vehicleTable: {
    actuatorOn: 'Travar',
    actuatorOff: 'Destravar',
    immobilize: 'Immobilize',
    de_immobilize: 'De-Immobilize',
    send_immobilization: 'Send immobilization command?',
    send_de_immobilization: 'Send de-immobilization command?',
    immobilization_not_authorized: 'You don\'t have permissions to execute the immobilization command.',
    all_vehicles: 'All',
    moving_vehicles: 'Moving',
    idle_vehicles: 'Idle',
    stopped_vehicles: 'Stopped',
    disconnected_vehicles: 'Disconnected',
    immo_pending: 'There is already a pending command for this vehicle. Please wait for the result.'
  },
  vehicleDetail: {
    rain: 'Ligado',
    sun: 'Desligado',
    door1: 'Porta',
    door2: 'Porta 2',
    opened: 'Aberta',
    closed: 'Fechada',
    show_route: 'Show route',
    follow: 'Seguir',
    unfollow: 'Parar',
    directions: 'Direcções',
    tow: 'Sensor de Plataforma',
    true: 'Ligado',
    false: 'Desligado'
  },
  poiTable: {
    showPOIs: 'Show POIs',
    edit_poi: 'Edit',
    delete_poi: 'Delete',
    hide_geofence: 'Hide geofence',
    hide_all: 'Hide all',
    show_geofence: 'Show geofence',
    show_all: 'Show all'
  },
  tripsTable: {
    trip: 'Trip',
    trips: 'Trips',
    total: 'Totals'
  },
  dashboard: {
    startdate: 'Start date',
    enddate: 'End Date',
    period_lastweek: 'Last week',
    period_lastmonth: 'Last month',
    period_last3month: 'Last 3 months'
  },
  navbar: {
    profile: 'Profile',
    notifications: 'Notifications',
    settings: 'Settings',
    logout: 'Log Out'
  },
  login: {
    login_user: 'E-mail',
    newPassword: 'Please enter a new password',
    login_password: 'Password',
    passwordsdontmatch: 'Passwords don\'t match',
    enterpassword: 'Please enter a password',
    enterpasswordagain: 'Please enter the password again',
    login_confirm_password: 'Confirm',
    login_button: 'Login',
    login_password_warn: 'The password can not be less than 6 digits',
    login_user_password_invalid: 'There is no account with that username or password you entered. Please check your credentials and try again.',
    login_user_disabled: 'Username is disabled',
    network_error: 'Could not connect to the server.',
    register: 'New user?',
    forgotPassword: 'Forgot your password?',
    signInWithGoogle: 'Google sign in'
  },
  alerts: {
    title: 'Alerts',
    get_alerts: 'Get Notifications'
  },
  profile: {
    user_account: 'Account',
    user_name: 'Name',
    user_email: 'E-mail',
    user_password: 'Password',
    user_phone: 'Phone',
    user_language: 'Language',
    user_timezone: 'Timezone',
    user_update_button: 'Update',
    user_updated: 'User information has been updated successfully.',
    user_name_required: 'Name field is required',
    user_email_required: 'Please input a valid email',
    email_required: 'Email field is required',
    user_password_lengh: 'The password can not be less than 8 digits',
    user_reports: 'Relatórios por email',
    daily_reports: 'Receber diariamente',
    weekly_reports: 'Receber semanalmente',
    monthly_reports: 'Receber mensalmente',
    continue: 'Continuar?',
    unsaved_changes: 'Existem alterações que não foram guardadas',
    inactive_vehicles_email: 'Inactive devices',
    inactive_vehicles_email_tooltip: 'Será enviado um email de alerta indicando os equipamentos que não comunicam há mais de 48 horas',
    daily_reports_tooltip: 'Email enviado diariamente com informação da actividade dos veículos',
    weekly_reports_tooltip: 'Email enviado semanalmente com informação da actividade dos veículos',
    monthly_reports_tooltip: 'Email enviado mensalmente com informação da actividade dos veículos',
    user_type_admin: 'Administrador',
    user_type_manager: 'Manager',
    user_type_operator: 'Operator'
  },
  settings: {
    alert_alarm: 'All',
    driver_password_changed: 'Password changed successfully',
    drivers_export_excel: 'Drivers Export',
    drivers_create_new_password: 'Do you want to generate excel with driver password information? If so, new passwords will be generated for all drivers.',
    add_alert: 'Add Alert',
    alert_sensor: 'Sensor',
    alert_digital_port: 'Digital Port',
    max_digital_port_open_time: 'Max time with open digital port (seconds)',
    alert_idle: 'Idle',
    rfid_edit_title: 'Edit Rfid',
    rfid_add_title: 'Adicionar Rfid',
    rfid_start: 'Start',
    rfid_end: 'End',
    commands: 'Commands',
    modules: 'Modules',
    by_vehicle: 'By Vehicle',
    by_maintenance: 'By Maintenance',
    vehicle: 'Vehicle',
    next_maintenance: 'Next Maintenance',
    remaining_kms: 'Remaining Kms',
    remaining_hours: 'Remaining Hours',
    createTempPassword: 'Generate temporary password',
    labelColor: 'Label color',
    labelTextSize: 'Tamanho do texto',
    min_temperature: 'Min temperature',
    max_temperature: 'Max temperature',
    calibrate_temperature: 'Calibrate temperature',
    maintenance: 'Maintenance',
    maintenance_add: 'Add Maintenance',
    maintenance_edit: 'Edit Maintenance',
    maintenance_name: 'Name',
    maintenance_start: 'Start',
    maintenance_period: 'Period',
    maintenance_start_date: 'Start Date',
    maintenance_months: 'Months',
    maintenance_hours: 'Hours',
    maintenance_type: 'Type',
    maintenance_type_totalDistance: 'Kms',
    maintenance_type_period: 'Period',
    maintenance_type_hours: 'Hours',
    maintenance_created: 'Maintenance created sucessfully!',
    maintenance_updated: 'Maintenance updated sucessfully!',
    maintenance_delete_info: 'Do you want to delete the maintenance ',
    maintenance_delete_title: 'Delete Maintenance',
    maintenance_delete_not_allowed: 'You do not have permission to delete maintenance.',
    maintenance_deleted: 'Maintenance has been deleted.',
    toleft: '------',
    toright: '------',
    showFullDate: 'Show date/hour from last location',
    geofence_coordinates: 'Coordenadas',
    vehicle_route_color: 'Route Color',
    vehicle_associated_driver: 'Driver',
    alert_test: 'Test',
    icons3d: '3D icons',
    showLabels: 'Show vehicles name',
    showPOIsLabels: 'Show POI name',
    reducePoiSize: 'Reduce POI size when zoomed out',
    search: 'Search...',
    empty_list: 'Nothing found',
    refresh: 'Recarregar aplicação',
    version: 'Version',
    connected: 'Connected',
    disconnected: 'Disconnected',
    connection: 'Connection',
    map: 'Map',
    about: 'About',
    logout: 'Logout',
    vehicles: 'Vehicles',
    pois: 'POIs',
    geofences: 'Geofences',
    title: 'Settings',
    route_history: 'History Route',
    route_match: 'Route match',
    view_speed_alerts: 'Show speed alerts',
    use_route_speed_limit: 'Use route speed limit',
    use_vehicle_speed_limit: 'Use vehicle speed limit',
    max_speed_threshold: 'Max speed threshold in Km/h',
    select: 'Selecionar',
    select_all: 'Select All',
    deselect_all: 'Deselect All',
    form_cancel: 'Cancel',
    form_confirm: 'Confirm',
    form_save: 'Save',
    transfer_selected: 'Selected',
    configuration: 'Configuration',
    time: 'Time',
    emails: 'Other Emails',
    other_emails: 'Other Emails (separated by comma)',
    alerts: 'Alerts',
    alarms: 'Alerts',
    alerts_type: 'Type',
    alerts_notificators: 'Notificators',
    alert_unidentifiedDriver: 'Unidentified Driver',
    alert_ignitionOff: 'Ignition Off',
    alert_ignitionOn: 'Ignition On',
    alert_geofenceEnter: 'Geofence Enter',
    alert_geofenceExit: 'Geofence Exit',
    alert_deviceOverspeed: 'Overspeed',
    alert_sos: 'SOS',
    alert_gpsAntennaCut: 'GPS Antena Cut',
    alert_tow: 'Tow',
    alert_highRpm: 'High RPM',
    alert_door: 'Porta aberta',
    alert_door1: 'Porta aberta',
    alert_hardAcceleration: 'Hard Acceleration',
    alert_hardBraking: 'Hard Braking',
    alert_hardCornering: 'Hard Cornering',
    alert_powerCut: 'Power Cut',
    alert_shock: 'Shock',
    alert_powerOn: 'Power Take-Off',
    alert_deviceFuelDrop: 'Fuel Drop',
    alert_driverChanged: 'Driver Changed',
    alert_breakdown: 'Breakdown',
    alert_inactivity: 'Inactive Devices',
    alert_maintenance: 'Maintenance Required',
    alert_deleted: 'Alert has been deleted',
    alert_delete_info: 'Do you want to delete the alert ',
    alert_delete_title: 'Delete Alert',
    alert_edit_confirm: 'Confirm',
    alert_edit_cancel: 'Cancel',
    alert_created: 'Alert created sucessfully!',
    alert_updated: 'Alert updated sucessfully!',
    alert_add: 'Add Alert',
    alert_edit: 'Edit Alert',
    alert_delete: 'Delete Alert',
    alert_overspeed_warning: 'Vehicle without defined top speed',
    alert_geofences_warning: 'Vehicle without associated geofences',
    alert_form_type: 'Type:',
    alert_form_type_placeholder: 'Select alert type',
    alert_form_vehicles: 'Vehicles:',
    alert_form_geofences: 'Geofences:',
    alert_form_all_vehicles: 'All vehicles',
    alert_form_vehicles_placeholder: 'Select vehicles',
    alert_form_notificator_select_on: 'Please select at least one',
    alert_form_notificators: 'Ways',
    alert_form_notificator_web: 'Web',
    alert_form_notificator_email: 'E-mail',
    alert_form_notificator_sms: 'SMS',
    alert_form_confirm: 'Save',
    alert_form_cancel: 'Cancel',
    alert_form_geofences_placeholder: 'Select geofences',
    alert_form_pois_placeholder: 'Select POIs',
    alert_form_linegeofences_placeholder: 'Select line geofences',
    alert_form_select_all: 'Select All',
    alert_form_deselect_all: 'Remove All',
    alert_form_ignitionoff_max_duration: 'Max Duration (Minutes)',
    alert_warning_type: 'This type of alert depends on vehicle\'s unit',
    alert_temperature: 'Temperature',
    alert_alarmEngineOn: 'Excessive Driving',
    alert_alarmEngineOff: 'Long Stop',
    vehicle_edit: 'Edit Vehicle',
    vehicle_name: 'Name',
    vehicle_licenseplate: 'License Plate',
    vehicle_model: 'Model',
    vehicle_form_category: 'Icon',
    vehicle_form_total_kms: 'Total Kms',
    vehicle_form_fuel_tank_capacity: 'Fuel Tank Capacity (L)',
    vehicle_speed_limit: 'Speed Limit (Km/h)',
    vehicle_form_name: 'Name',
    vehicle_form_group: 'Group',
    vehicle_form_groups_placeholder: 'Select group',
    vehicle_form_model: 'Model',
    vehicle_form_speed_limit: 'Speed Limit (Km/h)',
    vehicle_form_speed_minimal_duration: 'Speed Minimal Duration (minutes)',
    vehicle_form_by_date: 'Set by date',
    vehicle_form_apply_to_all: 'Apply configuration on all vehicles.',
    vehicle_kms_form: 'Edit Vehicle Kms',
    vehicle_kms_form_date: 'Date',
    vehicle_kms_form_total_kms: 'Total Kms',
    vehicle_total_hours: 'Total Hours',
    vehicle_kms_form_date_placeholder: 'Select date and time',
    vehicle_kms: 'Vehicle Kms',
    vehicle_lastposition: 'Last Position',
    vehicle_lastposition_address: 'Address',
    vehicle_notes: 'Notes',
    vehicle_gsm: 'GSM',
    vehicle_kms_traveled: 'Traveled since',
    vehicle_kms_current: 'Current',
    vehicle_hours_traveled: 'Hours since',
    vehicle_hours_current: 'Current Hours',
    vehicle_updated: 'Vehicle updated sucessfully!',
    vehicle_kms_updated: 'Vehicle kilometers updated sucessfully!',
    vehicle_geofences_updated: 'Geofences associated with the vehicle successfully updated!',
    vehicle_group: 'Group',
    vehicle_icon_car: 'Car',
    vehicle_icon_truck: 'Truck',
    vehicle_icon_truck2: 'Truck 2',
    vehicle_icon_trailer: 'Trailer',
    vehicle_icon_towtruck: 'Guincho',
    vehicle_icon_digger: 'Escavadora',
    vehicle_icon_crane: 'Grua',
    vehicle_icon_roadroller: 'Cilindro',
    vehicle_icon_dumpingtruck: 'Basculante',
    vehicle_icon_van: 'Van',
    vehicle_icon_default: 'Default',
    vehicle_icon_bicycle: 'Bicycle',
    vehicle_icon_person: 'Person',
    vehicle_icon_arrow: 'Arrow',
    vehicle_icon_bus: 'Bus',
    vehicle_icon_minibus: 'Minibus',
    vehicle_icon_tractor: 'Tractor',
    vehicle_icon_helicopter: 'Helicopter',
    vehicle_icon_motorcycle: 'Motorcycle',
    vehicle_icon_ambulance: 'Ambulance',
    vehicle_icon_boat: 'Boat',
    vehicle_icon_pickup: 'Pickup',
    vehicle_icon_mixer: 'Mixer',
    vehicle_icon_bulldozer: 'Bulldozer',
    vehicle_icon_cistern: 'Cistern',
    vehicle_edit_kms: 'Edit Kms',
    vehicle_edit_not_allowed: 'You don\'t have permissions to edit the vehicle.',
    vehicle_without_position: 'Vehicle without position',
    vehicle_associate_geofences: 'Associate Geofences',
    vehicle_get_qrcode: 'QR Code',
    vehicle_form_category_placeholder: 'Select Icon',
    speed_limit: 'Speed Limit',
    unidentified_driver_duration: 'Minimal Duration (minutes)',
    geofences_title: 'Geofences',
    geofence_name: 'Name',
    geofence_description: 'Description',
    geofence_speed_Limit: 'Speed Limit (Km/h)',
    geofences_type_geofences: 'Geofence:',
    geofences_type_linegeofences: 'Line Geofences:',
    linegeofences: 'Line Geofences',
    geofences_type_pois: 'POIs:',
    geofence_icon_square: 'Square',
    geofence_icon_triangle: 'Triangle',
    geofence_icon_airport: 'Airport',
    geofence_icon_aquarium: 'Aquarium',
    geofence_icon_attraction: 'Viewpoint',
    geofence_icon_barrier: 'Barrier',
    geofence_icon_building_alt1: 'Tall Building',
    geofence_icon_building: 'Building',
    geofence_icon_car_rental: 'Car Rental',
    geofence_icon_car_repair: 'Car Repair',
    geofence_icon_castle: 'Castle',
    geofence_icon_cemetery: 'Cemetery',
    geofence_icon_charging_station: 'Charging Station',
    geofence_icon_circle: 'Circle',
    geofence_icon_city: 'City',
    geofence_icon_embassy: 'Flag',
    geofence_icon_fuel: 'Fuel Station',
    geofence_icon_home: 'Home',
    geofence_icon_industry: 'Industry',
    geofence_icon_information: 'Information',
    geofence_icon_marker: 'Marker',
    geofence_icon_marker_stroked: 'Marker',
    geofence_icon_parking: 'Parking',
    geofence_icon_parking_garage: 'Parking Garage',
    geofence_icon_ranger_station: 'Fire Station',
    geofence_icon_recycling: 'Recycling',
    geofence_icon_residential_community: 'Residential',
    geofence_icon_star: 'Star',
    geofence_icon_town: 'Town',
    geofence_icon_town_hall: 'Town Hall',
    geofence_icon_village: 'Village',
    geofence_icon_warehouse: 'Warehouse',
    geofence_icon_waste_basket: 'Waste Basket',
    geofence_icon_windmill: 'Windmill',
    geofence_edit_title: 'Edit Zone',
    geofence_form_name: 'Name',
    geofence_form_description: 'Description',
    geofence_form_icon: 'Icon',
    geofence_form_color: 'Color',
    geofence_form_options: 'Options',
    geofence_form_speedlimit: 'Speed Limit (Km/h)',
    geofence_form_distance: 'Maximum distance',
    geofence_form_distance_apply_to_all: 'Apply the distance to all POIs',
    geofence_form_color_apply_to_all: 'Apply color to all POIs',
    zone: 'Zones',
    groups: 'Groups',
    group_edit: 'Edit Group',
    group_add: 'Add Group',
    group_name: 'Name',
    group_description: 'Description',
    group_form_name: 'Name',
    group_form_description: 'Description',
    group_updated: 'Group updated sucessfully!',
    group_deleted: 'Group has been deleted.',
    group_delete_info: 'Do you want to delete the group ',
    group_delete_title: 'Delete Group',
    group_edit_confirm: 'Confirm',
    group_edit_cancel: 'Cancel',
    group_created: 'Group created sucessfully!',
    group_select_vehicles_placeholder: 'Select Vehicles',
    group_select_drivers_placeholder: 'Select Drivers',
    group_select_geofences_placeholder: 'Select Geofences',
    group_select_pois_placeholder: 'Select POIs',
    group_select_linegeofences_placeholder: 'Select LineGeofences',
    group_add_not_allowed: 'You do not have permission to add groups.',
    group_edit_not_allowed: 'You do not have permission to edit groups.',
    group_delete_not_allowed: 'You do not have permission to delete groups.',
    drivers: 'Drivers',
    driver_name: 'Name',
    driver_uniqueId: 'ID (RFID / Username)',
    driver_email: 'E-Mail',
    driver_phone: 'Phone',
    driver_notes: 'Notes',
    driver_add: 'Add',
    driver_add_title: 'Add Driver',
    driver_edit: 'Edit',
    driver_edit_title: 'Edit Driver',
    driver_delete: 'Delete',
    driver_form_cancel: 'Cancel',
    driver_form_confirm: 'Confirm',
    driver_deleted: 'Driver has been deleted.',
    driver_delete_info: 'Do you want to delete the driver ',
    driver_delete_title: 'Delete Driver',
    driver_created: 'Driver created sucessfully!',
    driver_updated: 'Driver updated sucessfully!',
    driver_add_not_allowed: 'You do not have permission to add drivers.',
    driver_edit_not_allowed: 'You do not have permission to edit drivers.',
    driver_delete_not_allowed: 'You do not have permission to delete drivers.',
    driver_uniqueid_create: 'Generate',
    driver_uniqueid_create_label: 'Generate Identifier',
    driver: 'Driver',
    reports: 'Reports',
    automatic_reports: 'Automatic reports',
    activate_automatic_trip_report: 'Trip report - Enable automatic report',
    activate_automatic_location_report: 'Location report - Enable automatic report',
    activate_automatic_zone_report: 'Zone report - Enable automatic report',
    activate_automatic_speeding_report: 'Overspeed report - Enable automatic report',
    activate_automatic_refueling_report: 'Refueling report - Enable automatic report',
    activate_automatic_fueldrop_report: 'Fuel drop report - Enable automatic report',
    activate_automatic_activity_report: 'Activity report - Enable automatic report',
    activate_automatic_events_report: 'Events report - Enable automatic report',
    activate_automatic_kms_report: 'Kms report - Enable automatic report',
    report_periodicity_daily: 'Receive daily',
    report_periodicity_weekly: 'Receive weekly',
    report_periodicity_monthly: 'Receive monthly',
    report_by_group: 'Receive an email for each vehicle group',
    uniqueid_duplicated: 'RFID Key duplicatede',
    pois_title: 'POIs',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    name_required: 'Name field is required',
    email_format_invalid: 'Please input a valid email',
    email_or_phone_required: 'At least one of these fields must have a valid value.',
    users: 'Users',
    user_edit: 'Edit User',
    user_add: 'Add User',
    user_name: 'Name',
    user_email: 'Email',
    user_phone: 'Phone',
    user_language: 'Language',
    user_timezone: 'Timezone',
    user_type: 'Profile',
    user_form_manage_devices: 'Allow change device settings',
    user_password: 'Password',
    user_form_type_placeholder: 'Select Profile',
    user_deleted: 'User has been deleted.',
    user_delete_info: 'Do you want to delete the user ',
    user_delete_title: 'Delete User',
    user_created: 'User created sucessfully!',
    user_updated: 'User updated sucessfully!',
    user_create_not_allowed: 'You do not have permissions to create users.',
    user_edit_not_allowed: 'You do not have permissions to edit users.',
    user_duplicated_entry: 'User already exists.',
    temporary_links: 'Temporary Links',
    expiration_date: 'Expiration Date',
    user_temporary_link: 'Link',
    temporary_link_add: 'Create Temporary Link',
    temporary_link_edit: 'Edit Temporary Link',
    temporary_link_created: 'Temporary link created sucessfully!',
    temporary_link_create_not_allowed: 'You do not have permissions to create temporary links.',
    temporary_link_deleted: 'Temporary link has been deleted.',
    temporary_link_delete_info: 'Do you want to delete the temporary link ',
    temporary_link_delete_title: 'Delete Temporary link',
    full: 'Full',
    partial: 'Partial',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    selectStartTime: 'Select Start Time',
    selectEndTime: 'Select End Time',
    edit_vehicle_kms_warning: 'Neste veículo o calculo dos Kms é feito directamente no equipamento, para actualizar os Kms é necessário alterar a configuração do mesmo. Por favor contacte o suporte.'
  },
  geofence: {
    showGeofences: 'Show Geofences',
    geofence_name: 'Name',
    geofence_edit: 'Edit',
    geofence_delete: 'Delete',
    geofence_deleted: 'Geofence has been deleted',
    geofence_delete_info: 'Do you want to delete geofence ',
    geofence_delete_title: 'Delete Geofence',
    geofence_edit_title: 'Edit Geofence',
    geofence_edit_name: 'Please enter the name of this geofence...',
    geofence_edit_confirm: 'Confirm',
    geofence_edit_cancel: 'Cancel',
    geofence_edit_canceled: 'Edit canceled',
    geofence_edited: 'Geofence edited sucessfully!',
    poi_delete_info: 'Do you want to delete POI ',
    poi_delete_title: 'Delete POI',
    poi_edited: 'POI edited sucessfully!',
    poi_deleted: 'POI has been deleted',
    poi_edit_title: 'Edit POI',
    poi_edit_name: 'Please enter the name of this POI...',
    searchGeofence: 'Search geofence',
    linegeofence_delete_info: 'Do you want to delete geofence line ',
    linegeofence_delete_title: 'Delete Geofence Line',
    linegeofence_edited: 'Geofence line edited sucessfully!',
    linegeofence_deleted: 'Geofence line deleted',
    linegeofence_edit_title: 'Edit Geofence Line',
    linegeofence_edit_name: 'Please enter the name of this geofence line...',
    edit_geofence: 'Edit',
    delete_geofence: 'Delete'
  },
  report: {
    selector_search: 'Search',
    select_vehicles: 'Selected',
    select_vehicles_placeholder: 'Vehicles',
    select_groups: 'Selected',
    select_groups_placeholder: 'Groups',
    select_geofences: 'Selected',
    select_geofences_placeholder: 'Geofences',
    select_period: 'Select period',
    period: 'Period',
    date_start: 'Start date',
    date_end: 'End date',
    generate_report: 'Generate report',
    validate_period: 'Please select a period',
    select_all: 'All'
  },
  layout: {
    deviceOnline: 'Device Online',
    deviceMoving: 'Device Moving',
    deviceStopped: 'Device Stopped',
    ignitionOff: 'Ignition Off',
    ignitionOn: 'Ignition On',
    deviceOverspeed: 'Overspeed',
    geofenceEnter: 'Geofence Enter',
    geofenceExit: 'Geofence Exit',
    test: 'Test Notification',
    newVersion: 'A new version of this app is available. Click here to update.'
  },
  permissions: {
    customreport_vistawaste_activity:	'Actividade Vistawaste',
    externalmaintenance: 'Módulo Externo de Manutenção',
    externalreports: 'Módulo Externo de Relatórios',
    externalreports_activity_cocacola_day: 'Actividade Coca-Cola diário',
    externalreports_activity_cocacola_month: 'Actividade Coca-Cola mensal',
    externalreports_activity_cocacola_month_detailed: 'Actividade Coca-Cola mensal detalhado',
    externalreports_luanda_speeding: 'Velocidades em localidade',
    externalreports_passenger_pickup_per_passenger: 'Recolhas por passageiro',
    externalreports_passenger_pickup_per_passenger_total: 'Recolhas totais por passageiro',
    externalreports_passenger_pickup_per_vehicle: 'Recolhas por veículo',
    externalreports_passenger_pickup_per_vehicle_total: 'Recolhas totais por veí­culo',
    externalreports_report_group_km: 'Distâncias por grupo',
    externalreports_report_km: 'Kms',
    externalreports_report_misuse: 'Utilização indevida',
    externalreports_rfid_cards: 'Configuração de cartões',
    externalreports_rfid_readers: 'Configuração de leitores',
    externalreports_usage: 'Utilização Resumido',
    externalreports_wapo_speeding: 'Velocidade WAPO',
    scheduler: 'Report scheduler module'
  }
}
