import defaultSettings from '../../settings'
import Vue from 'vue'
import { getPartnerData } from 'fleetmap-partners'

const { sidebarLogo } = defaultSettings

const state = {
  sidebarLogo: sidebarLogo,
  matchRoutes: false,
  showLabels: true,
  showPOIsLabels: true,
  reducePoiSize: false,
  vehicles3d: false,
  followVehicle: false,
  showFullDate: getPartnerData().showFullDate,
  showStopDate: getPartnerData().showStopDate,
  labelColor: '#000000',
  labelTextSize: 12,
  orderDevicesBy: '',
  viewSpeedAlerts: true,
  viewEventsOnRoute: true,
  viewIdlePoints: true
}

const mutations = {
  SET_ORDER_DEVICES_BY(state, orderDevicesBy) {
    state.orderDevicesBy = orderDevicesBy
  },

  SET_SHOW_LABELS(state, value) {
    state.showLabels = value
  },
  SET_SHOW_POIS_LABELS(state, value) {
    state.showPOIsLabels = value
  },
  SET_REDUCE_POI_SIZE(state, value) {
    state.reducePoiSize = value
  },
  CHANGE_SETTING: (state, { key, value }) => {
    Vue.$log.debug(key + ':' + value)
    if (Object.prototype.hasOwnProperty.call(state, key)) {
      state[key] = value
    } else {
      Vue.$log.error('invalid setting', key)
    }
  },
  SET_SHOW_FULLDATE(state, value) {
    state.showFullDate = value
  }
}

const actions = {
  setOrderDevicesBy({ commit, state }, value) {
    commit('SET_ORDER_DEVICES_BY', value)
  },
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  setShowLabels({ commit }, value) {
    commit('SET_SHOW_LABELS', value)
  },
  setShowPOIsLabels({ commit }, value) {
    commit('SET_SHOW_POIS_LABELS', value)
  },
  setReducePoiSize({ commit }, value) {
    commit('SET_REDUCE_POI_SIZE', value)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

