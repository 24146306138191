import styles from '../styles/element-variables.scss'
import { getPartnerData } from 'fleetmap-partners'

function initSurvey(token) {
  const s = document.createElement('script')
  s.src = `https://survey.survicate.com/workspaces/${token}/web_surveys.js`
  s.async = true
  const e = document.getElementsByTagName('script')[0]
  e.parentNode.insertBefore(s, e)
}

export function initSupportChat() {
  switch (hostname) {
    case 'mac.pinme.io':
    case 'web.fleetrack.cl':
      initSurvey('60bd590459b33f47d8dd8abccc0dc8d7')
      break
    case 'wuizy.co.ao':
      initSurvey('51e321bab007a7f8e6b0575f91f20939')
      break
    default:
  }
}

export const hostname = window.location.hostname.replace('dev.', '')

export function getFavIcon() {
  return '/img/favicon/' + hostname + '.png'
}

export function getLogo() {
  return '/img/logos/' + hostname + '.png'
}

export function getThemeColor() {
  if (hostname === 'wuizy.co.ao') { return styles.success }
  if (hostname === 'web.fleetrack.cl') { return styles.primary }
  if (hostname === 'map.able-on.mobi') { return styles.primary }
  return styles.success
}

export function getTitle() {
  const partner = getPartnerData(hostname)
  return partner && partner.title
}

export function getOneSignalAppId() {
  const partnerData = getPartnerData(window.location.hostname)
  return partnerData && partnerData.appId
}

export function hasSVG() {
  return hostname === 'wuizy.co.ao' ||
    // hostname.includes('localhost') ||
    hostname.includes('macp.pinme.io')
}

export function partnerData() {
  return getPartnerData(window.location.hostname)
}
