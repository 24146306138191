import { awsConfig } from '@/amplify'

export function getServerHost() {
  return window.location.hostname
}

export function newDomain() {
  return true
}

function auth(action) {
  const redirect = awsConfig.oauth.redirectSignIn
  return `https://${awsConfig.oauth.domain}/${action}?client_id=${awsConfig.aws_user_pools_web_client_id}&redirect_uri=${redirect}&response_type=code&scope=${awsConfig.oauth.scope.join('+')}`
}

export function signUp() {
  return auth('signup')
}

export function signIn() {
  return auth('signin')
}

export function forgotPassword() {
  return auth('forgotPassword')
}

export function signOut() {
  return `https://${awsConfig.oauth.domain}/logout?client_id=${awsConfig.aws_user_pools_web_client_id}&response_type=code&scope=${awsConfig.oauth.scope.join('+')}&logout_uri=${awsConfig.oauth.redirectSignOut}`
}

export function changePassword() {
  return auth('changePassword')
}
