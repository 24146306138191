<template>
  <div>
    <el-dialog v-if="user.id !== 0" :title="$t('profile.user_account')" :visible.sync="dialogVisible" :before-close="handleClose" @open="dirty=false">
      <el-form ref="user" :model="user" :rules="rules" label-width="auto">
        <el-form-item :label="$t('profile.user_name')" prop="name">
          <el-input v-model="user.name" @input="dirty=true" />
        </el-form-item>
        <el-form-item :label="$t('profile.user_email')" prop="email">
          <el-input v-model="user.email" @input="dirty=true" />
        </el-form-item>
        <el-form-item :label="$t('profile.user_password')" prop="password">
          <el-input
            :key="passwordType"
            ref="password"
            v-model="user.password"
            :show-password="true"
            :type="passwordType"
            name="password"
            @input="dirty=true"
          />
        </el-form-item>
        <el-form-item :label="$t('profile.user_phone')">
          <el-input v-model="user.phone" @input="dirty=true" />
        </el-form-item>
        <el-form-item :label="$t('profile.user_timezone')">
          <el-select v-model="user.attributes.timezone" @change="dirty=true">
            <el-option v-for="timezone in timezones" :key="timezone.value" :label="timezone.text" :value="timezone.value" />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('profile.user_language')">
          <el-select v-model="user.attributes.lang" @change="dirty=true">
            <el-option v-for="lang in languages" :key="lang.value" :label="lang.text" :value="lang.value" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" :disabled="!dirty" @click="submit">{{ $t('profile.user_update_button') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { appOffline } from '@/utils/utils'
import { traccar } from '@/api/traccar-api'
import { languages, setLanguage } from '@/lang'
import { timezones } from '@/utils/consts'

export default {
  name: 'Profile',
  data() {
    return {
      dirty: false,
      dialogVisible: false,
      languages: languages,
      timezones: timezones,
      rules: {
        name: [
          { required: true, message: this.$t('profile.user_name_required'), trigger: 'blur' }
        ],
        email: [
          { type: 'email', required: true, message: this.$t('profile.user_email_required'), trigger: 'blur' }
        ],
        password: [
          { required: true, min: 6, message: this.$t('profile.user_password_lengh'), trigger: 'blur' }
        ]
      },
      passwordType: 'password',
      loading: false
    }
  },
  computed: {
    ...mapGetters(['avatar', 'user']),
    offline() { return appOffline() }
  },
  methods: {
    handleClose(done) {
      if (this.dirty) {
        this.$confirm(this.$t('profile.unsaved_changes'), this.$t('profile.continue'))
          .then(() => { done() })
          .catch(() => {})
      } else {
        done()
      }
    },
    async logout() {
      this.loading = true
      await this.$store.dispatch('user/logout')
      window.location.reload()
    },
    handleCommand(command) {
      if (command === 'profile') {
        this.$store.dispatch('user/getUser').finally(() => { this.dialogVisible = true })
      }
    },
    submit() {
      this.$refs.user.validate(valid => {
        if (valid) {
          this.loading = true
          traccar.updateUser(this.user.id, this.user
          ).then(({ data }) => {
            this.dirty = false
            setLanguage(data.attributes.lang)
            this.$message({
              message: this.$t('profile.user_updated'),
              type: 'success',
              duration: 5 * 1000
            })
          }).catch((e) => {
            this.$message({
              message: e,
              type: 'error',
              duration: 5 * 1000
            })
          }).finally(() => { this.loading = false })
        }
      })
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    }
  }
}
</script>

<style lang="scss">
  @import '../../styles/element-variables.scss';
  .el-avatar {
    opacity: 0.95;
    font-weight: bold;
  }
  a:link {
    text-decoration: none;
  }
  .right-menu {
      position: absolute;
      right: 15px;
    top:15px;
      background: rgba(0, 0, 0, 0);
      z-index: 99;
  }
  $dark_gray:#889aa4;

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 1px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

</style>
