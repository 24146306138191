<template>
  <div>
    <img style="width:100%; margin-top: 13px; margin-bottom: 0" :src="imageUrl" alt="" />
    <div class="content">
      <div class="title">
        <copy-coordinates :current-position="{latitude: lngLat[1], longitude: lngLat[0]}"></copy-coordinates>
        <img v-if="properties.speedLimit" style="vertical-align:middle; padding: 4px" :src="`github/regulatory--maximum-speed-limit-${properties.speedLimit}--g1.svg`" alt="">
        <i v-else :class="properties.icon" :style="'color: '+properties.color" />
        {{ getDescription(properties) }}
      </div>
      <div>
        <el-button
          type="text"
          @click="showRoute"
        >{{ properties.device.name }}
        </el-button>
        <span v-if="properties.driver" style="padding-left: 10px">{{ properties.driver.name }}</span>
      </div>
      <span><b>{{ properties.content }}</b></span>
      <div class="subtitle">
        <i
          v-if="properties.type === alertType.ignitionOff"
          class="fas fa-car"
          style="width: 15px; color: #F5365C"
        />
        {{ properties.position && properties.position.address }}
      </div>
      <div style="padding-top: 5px;padding-bottom: 5px;">
        <span>{{ new Date(properties.timestamp).toLocaleString() }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import * as alertType from '@/alerts/alertType'
import CopyCoordinates from '@/components/CopyCoordinates.vue'
import { serverBus } from '@/main'
import * as event from '@/events'
import { mapGetters } from 'vuex'
import { getEventDescription } from '@/utils/utils'

export default {
  name: 'EventPopUp',
  components: { CopyCoordinates },
  data: function() {
    return {
      imageUrl: '',
      properties: {}
    }
  },
  computed: {
    ...mapGetters(['historyMode']),
    alertType() {
      return alertType
    }
  },
  mounted() {
    const lat = this.lngLat[1].toFixed(6)
    const lon = this.lngLat[0].toFixed(6)
    const ll = `${lat},${lon}`
    this.imageUrl = `https://maps.googleapis.com/maps/api/streetview?location=${ll}&size=456x456&key=AIzaSyB0NhjAXuvVBvWYnlFtyE6hCIpQplvv2K8`
    const start = (this.isMobile && this.$device.ios) ? 'maps' : 'https'
    this.navigateUrl = `${start}://maps.google.com/maps?daddr=${ll}`
  },
  beforeDestroy() {
    Vue.$log.debug('destroying EventPopUp', this.properties)
  },
  methods: {
    showRoute() {
      serverBus.$emit(event.deviceSelected, this.properties.device)
      if (!this.historyMode) {
        this.$store.dispatch('transient/toggleHistoryMode')
        serverBus.$emit(event.showRoutesChanged)
      }
      setTimeout(() => {
        serverBus.$emit('autoSliderChange', new Date(this.properties.timestamp).getTime() / 1000)
      }, 3000)
    },
    getDescription(e) {
      return getEventDescription(e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

.content {
  font-family: AmazonEmber,serif;
  padding-left: 6px;
  padding-right: 6px;
}
.title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #32325D;
  padding-bottom: 2px;
  padding-top: 1px;
  overflow: auto;
}
.subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #32325D;
  padding-bottom: 2px;
  overflow: auto;
}
.right {
  padding:10px;
  float: right;
}
.coordsIcon{
  float: right;
  color: $--color-primary;
  padding-top: 15px;
  padding-right: 10px;
}
</style>
