// noinspection JSNonASCIINames,NonAsciiCharacters
export default {
  viewEventsOnRoute: 'Visualizar eventos na rota',
  viewIdlePoints: 'Visualizar ralenti na rota',
  'Allow poi collisions': 'Permitir colisão de POIs',
  'Show poi circles': 'Mostrar raio do poi',
  'Último vehículo utilizado': 'Último veículo utilizado',
  Icon: 'Ícone',
  sensor: 'Ligado',
  'Al activar esta opción recibirá mensajes de alarmas en su whatsapp, si deséa cancelar vuelva a esta pantalla y desactive la opcion. Desea continuar?': 'Ao activar esta opção receberá mensagens de alerta no seu whatsapp. Deseja continuar?',
  'Enviámos un mensaje de confirmación a su móvil.': 'Enviámos uma confirmação para o seu whatsapp',
  Yes: 'Sim',
  No: 'Não',
  'Export excel with new passwords': 'Exportar excel com novas passwords',
  'Seleccionar fecha': 'Seleccionar data',
  'Use road speed limit': 'Usar limite da via',
  shareScreen: 'Compartilhar tela',
  Copiado: 'Copiado',
  Move: 'Mover',
  copyClipboardMessage: 'copiado para a área de transferência (clipboard).',
  app: {
    connectionLost: 'Ligação perdida',
    reconnect: 'Deseja restabelecer a ligação com o servidor?'
  },
  route: {
    map: 'Mapa',
    dashboard: 'Dashboard',
    reports: 'Relatórios',
    report_trip_title: 'Relatório de viagens',
    report_location_title: 'Relatório de posições',
    report_zone_crossing: 'Relatório de zonas',
    report_speeding: 'Relatório de excessos de velocidade',
    report_refueling: 'Relatório de abastecimentos',
    report_fueldrop: 'Relatório de perda de combustível',
    report_events: 'Relatório de Eventos',
    report_activity: 'Relatório de actividade',
    report_kms: 'Relatório de kms',
    customreport_vistawaste_activity_title: 'Relatório de Actividade Vista Waste',
    settings: 'Configurações',
    duration: 'Duração',
    nodata: 'Não existem dados para o período selecionado',
    scheduler: 'Relatórios Automáticos',
    management: 'Gestão',
    odoo: 'Gestão',
    manual: 'Manual',
    tacho: 'Tacógrafo'
  },
  map: {
    poi_click_on_map: 'Clique no mapa para criar o Marco.',
    poi_create_error: 'Não tem permissão para criar Marcos',
    geofence_create_title: 'Nova Cerca Eletrônica',
    geofence_create_name: 'Por favor indique o nome da cerca eletrônica.',
    geofence_created: 'Cerca eletrônica criada com sucesso!',
    geofence_create_canceled: 'Criação de cerca eletrônica cancelada!',
    poi_create_title: 'Novo Marco',
    poi_create_name: 'Por favor indique o nome do Marco.',
    poi_created: 'Marco criado com sucesso!',
    line_create_name: 'Por favor indique o nome da rota.',
    line_create_title: 'Nova Rota',
    line_created: 'Rota criada com sucesso!',
    poi_create_canceled: 'Criação do Marco cancelada!',
    create_confirm: 'Confirmar',
    create_cancel: 'Cancelar',
    send_immobilization_command_ok: 'Comando enviado com sucesso!',
    line_create_canceled: 'Criação da rota cancelada!'
  },
  vehicleList: {
    title: 'Veículos',
    search: 'Pesquisar...',
    empty_list: 'Sem resultados',
    column_name: 'Nome',
    column_speed: 'Km/h',
    column_lastUpdate: 'Última Actualização',
    order_by_status: 'Ordenar por Estado',
    order_by_vehicle: 'Ordenar por Veículo',
    order_by_group: 'Ordenar por Grupo',
    order_by_last_update: 'Ordenar pela última actualização',
    order_by_fuel_level: 'Ordenar por nível de combustível',
    order_by: 'Ordenar por...'
  },
  vehicleTable: {
    immobilize: 'Imobilizar',
    de_immobilize: 'Remobilizar',
    send_immobilization: 'Enviar comando de imobilização para o veículo',
    send_de_immobilization: 'Enviar comando de remobilização para veículo',
    immobilization_not_authorized: 'Não tem permissões para executar o comando de imobilização.',
    all_vehicles: 'Todos',
    moving_vehicles: 'Em Movimento',
    idle_vehicles: 'Ralenti',
    stopped_vehicles: 'Parado',
    disconnected_vehicles: 'Desconectado'
  },
  vehicleDetail: {
    show_route: 'Rota'
  },
  poiTable: {
    showPOIs: 'Ver Marcos',
    edit_poi: 'Editar',
    delete_poi: 'Apagar',
    hide_geofence: 'Esconder zona',
    hide_all: 'Esconder todos',
    show_geofence: 'Mostrar zona',
    show_all: 'Mostrar todos'
  },
  tripsTable: {
    trip: 'Viagem',
    trips: 'Viagens',
    total: 'Totais'
  },
  dashboard: {
    startdate: 'Data de Início',
    enddate: 'Data de Fim',
    period_lastweek: 'Última semana',
    period_lastmonth: 'Último mês',
    period_last3month: 'Últimos 3 meses'
  },
  navbar: {
    profile: 'Perfil',
    notifications: 'Notificações',
    settings: 'Configurações',
    logout: 'Sair'
  },
  login: {
    signInWithGoogle: 'Entrar com a Google',
    login_password: 'Palavra-chave',
    login_button: 'Entrar',
    login_password_warn: 'A Palavra-chave não pode ter menos de 6 caracteres',
    login_user: 'Usuário',
    login_user_password_invalid: 'Não existe nenhuma conta com o usuário ou password indicados. Por favor verifique as credenciais e tente novamente.',
    login_user_disabled: 'O usuário encontra-se bloqueado.',
    network_error: 'Não foi possível estabelecer ligação com o servidor.',
    register: 'Novo usuário?',
    forgotPassword: 'Esqueceu a sua senha?'
  },
  profile: {
    user_account: 'Usuário',
    user_name: 'Nome',
    user_email: 'E-mail',
    user_password: 'Palavra-chave',
    user_phone: 'Telefone',
    user_language: 'Idioma',
    user_timezone: 'Fuso horário',
    user_update_button: 'Gravar',
    user_updated: 'Informação do usuário foi actualizada.',
    user_name_required: 'O campo nome é obrigatório',
    user_email_required: 'Por favor indique um e-mail válido',
    email_required: 'O campo e-mail é obrigatório',
    user_password_lengh: 'A Palavra-chave não pode ter menos de 8 caracteres'
  },
  settings: {
    alert_alarm: 'Todos',
    alert_alarmEngineOn: 'Condução excessiva',
    alert_alarmEngineOff: 'Paragem longa',
    driver_password_changed: 'Palavra-chave alterada com sucesso',
    drivers_export_excel: 'Exportação de Motoristas',
    drivers_create_new_password: 'Pretende gerar o excel com informação das password dos motoristas? Em caso afirmativo serão geradas novas passwords para todos os motoristas.',
    alert_digital_port: 'Porta Digital',
    max_digital_port_open_time: 'Tempo máximo com porta digital aberta (segundos)',
    rfid_start: 'Inicio',
    rfid_end: 'Fim',
    commands: 'Comandos',
    modules: 'Módulos',
    showFullDate: 'Mostrar data/hora da última comunicação',
    createTempPassword: 'Gerar password temporária',
    by_vehicle: 'Por Veículo',
    by_maintenance: 'Por Manutenção',
    next_maintenance: 'Próxima Manutenção',
    remaining_kms: 'Kms Restantes',
    remaining_hours: 'Horas Restantes',
    labelColor: 'Cor da label',
    min_temperature: 'Temperatura mínima',
    max_temperature: 'Temperatura máxima',
    calibrate_temperature: 'Calibrar temperatura',
    maintenance: 'Manutenção',
    maintenance_start: 'Inicio',
    maintenance_period: 'Periodicidade',
    maintenance_type: 'Tipo',
    maintenance_type_totalDistance: 'Kms',
    maintenance_type_period: 'Tempo',
    maintenance_type_hours: 'Horas',
    maintenance_start_date: 'Data de Inicio',
    maintenance_months: 'Meses',
    maintenance_hours: 'Horas',
    maintenance_add: 'Adicionar Manutenção',
    maintenance_edit: 'Editar Manutenção',
    maintenance_name: 'Nome',
    maintenance_created: 'Manutenção criada com sucesso!',
    maintenance_updated: 'Manutenção editada com sucesso!',
    maintenance_delete_info: 'Pretende apagar a manutenção ',
    maintenance_delete_title: 'Apagar Manutenção',
    maintenance_delete_not_allowed: 'Não tem permissões para criar a manutenção.',
    maintenance_deleted: 'A manutenção foi apagada.',
    vehicle_route_color: 'Cor da rota',
    zone: 'Zonas',
    showLabels: 'Mostrar nome do veículo',
    showPOIsLabels: 'Mostrar nome do POI',
    reducePoiSize: 'Reduzir tamanho do Poi ao diminuir o zoom',
    search: 'Pesquisar...',
    empty_list: 'Sem resultados',
    refresh: 'Recarregar',
    version: 'Versão',
    connected: 'Ligado',
    disconnected: 'Desligado',
    connection: 'Ligação',
    map: 'Mapa',
    about: 'Informações',
    logout: 'Sair',
    vehicles: 'Veículos',
    pois: 'Marcos',
    geofences: 'Cercas Eletrônicas',
    title: 'Configurações',
    route_history: 'Histórico de Rota',
    route_match: 'Rota na estrada',
    view_speed_alerts: 'Mostrar alertas de velocidade',
    use_route_speed_limit: 'Usar limites da estrada',
    use_vehicle_speed_limit: 'Usar limite definido no carro',
    max_speed_threshold: 'Tolerância Máxima em Km/h',
    select_all: 'Selecionar Todos',
    deselect_all: 'Remover Todos',
    form_cancel: 'Cancelar',
    form_confirm: 'Confirmar',
    form_save: 'Guardar',
    transfer_selected: 'Selecionados',
    configuration: 'Configuração',
    time: 'Horário',
    emails: 'Outros Emails',
    other_emails: 'Outros Emails (separados por vírgula)',
    add_alert: 'Add Alert',
    alerts: 'Alertas',
    alarms: 'Alertas',
    alerts_type: 'Tipo',
    alerts_notificators: 'Vias',
    alert_unidentifiedDriver: 'Motorista não identificado',
    alert_ignitionOff: 'Ignição Desligada',
    alert_ignitionOn: 'Ignição Ligada',
    alert_geofenceEnter: 'Entrada em Cerca Eletrônica / Rota / Marco',
    alert_geofenceExit: 'Saída de Cerca Eletrônica / Rota / Marco',
    alert_deviceOverspeed: 'Excesso de Velocidade',
    alert_sos: 'Pânico',
    alert_gpsAntennaCut: 'Corte Antena GPS',
    alert_tow: 'Reboque',
    alert_highRpm: 'RPM Alta',
    alert_door: 'Porta aberta',
    alert_door1: 'Porta aberta',
    alert_hardAcceleration: 'Acelaração Brusca',
    alert_hardBraking: 'Travagem Brusca',
    alert_hardCornering: 'Viragem Brusca',
    alert_powerCut: 'Corte de Corrente',
    alert_shock: 'Acidente',
    alert_powerOn: 'Tomada de Força',
    alert_deviceFuelDrop: 'Perda de Combustível',
    alert_driverChanged: 'Motorista Identificado',
    alert_breakdown: 'Avaria',
    alert_maintenance: 'Manutenção',
    alert_idle: 'Ralenti',
    alert_inactivity: 'Inactividade dos equipamentos',
    alert_deleted: 'O Alerta foi apagado.',
    alert_delete_info: 'Pretende apagar o alerta de ',
    alert_delete_title: 'Apagar Alerta',
    alert_edit_confirm: 'Confirmar',
    alert_edit_cancel: 'Cancelar',
    alert_created: 'Alerta criado com sucesso!',
    alert_updated: 'Alerta actualizado com sucesso!',
    alert_add: 'Adicionar Alerta',
    alert_edit: 'Editar Alerta',
    alert_delete: 'Apagar Alerta',
    alert_overspeed_warning: 'Veículo sem velocidade máxima definida',
    alert_geofences_warning: 'Veículo sem Cercas Eletrônicas associadas',
    alert_form_type: 'Tipo:',
    alert_form_type_placeholder: 'Selecionar o tipo de alerta',
    alert_form_vehicles: 'Veículos:',
    alert_form_geofences: 'Cercas Eletrônicas:',
    alert_form_pois: 'Marcos:',
    alert_form_all_vehicles: 'Todos os veículos',
    alert_form_vehicles_placeholder: 'Selecionar veículos',
    alert_form_notificator_web: 'Web',
    alert_form_notificator_email: 'E-mail',
    alert_form_notificator_sms: 'SMS',
    alert_form_confirm: 'Gravar',
    alert_form_cancel: 'Cancelar',
    alert_form_geofences_placeholder: 'Selecionar Cercas Eletrônicas',
    alert_form_pois_placeholder: 'Selecionar Marcos',
    alert_form_linegeofences_placeholder: 'Selecionar corredores',
    alert_form_select_all: 'Selecionar Todos',
    alert_form_deselect_all: 'Remover Todos',
    alert_form_ignitionoff_max_duration: 'Duração Máxima (Minutos)',
    alert_warning_type: 'Este tipo de alerta está dependente do tipo de unidade instalada no veículo.',
    alert_form_notificator_select_on: 'Por favor seleccionar pelo menos um',
    alert_form_notificators: 'Vias',
    vehicle_edit: 'Editar Veículo',
    vehicle_name: 'Nome',
    vehicle_form_group: 'Grupo',
    vehicle_form_groups_placeholder: 'Selecionar Grupo',
    vehicle_licenseplate: 'Placa',
    vehicle_model: 'Modelo',
    vehicle_form_total_kms: 'Total de Kms',
    vehicle_total_hours: 'Total Horas',
    vehicle_form_fuel_tank_capacity: 'Capacidade do Depósito (L)',
    vehicle_speed_limit: 'Vel. Limite (Km/h)',
    vehicle_form_name: 'Nome',
    vehicle_form_model: 'Modelo',
    vehicle_form_speed_limit: 'Limite de Velocidade (Km/h)',
    vehicle_form_speed_minimal_duration: 'Duração Mínima (minutos)',
    vehicle_form_by_date: 'Definir por data',
    vehicle_form_apply_to_all: 'Aplicar configuração em todos os veículos.',
    vehicle_kms_form: 'Editar Kms do Veículo',
    vehicle_kms_form_date: 'Data',
    vehicle_kms_form_total_kms: 'Total Kms',
    vehicle_kms_form_date_placeholder: 'Selecionar data e hora',
    vehicle_kms: 'Kms do Veículo',
    vehicle_lastposition: 'Última Posição',
    vehicle_lastposition_address: 'Local',
    vehicle_notes: 'Observações',
    vehicle_gsm: 'GSM',
    vehicle_kms_traveled: 'Percorridos desde',
    vehicle_kms_current: 'Actuais',
    vehicle_hours_traveled: 'Horas passadas desde',
    vehicle_hours_current: 'Horas actuais',
    vehicle_updated: 'Veículo actualizado com sucesso!',
    vehicle_kms_updated: 'Kms do veículo actualizado com sucesso!',
    vehicle_geofences_updated: 'Cerca Eletrônica / Rota / Marco associadas ao veículo actualizadas com sucesso!',
    vehicle_group: 'Grupo',
    vehicle_icon_car: 'Carro',
    vehicle_icon_ambulance: 'Ambulância',
    vehicle_icon_truck: 'Caminhão',
    vehicle_icon_truck2: 'Cavalo mecânico',
    vehicle_icon_trailer: 'Semi-reboque',
    vehicle_icon_van: 'Van',
    vehicle_icon_default: 'Normal',
    vehicle_icon_mixer: 'Betoneira',
    vehicle_icon_bicycle: 'Bicicleta',
    vehicle_icon_person: 'Pessoa',
    vehicle_icon_arrow: 'Seta',
    vehicle_icon_bus: 'Ônibus',
    vehicle_icon_tractor: 'Trator',
    vehicle_icon_bulldozer: 'Trator de Esteira',
    vehicle_icon_cistern: 'Caminhão Tanque',
    vehicle_icon_helicopter: 'Helicóptero',
    vehicle_icon_motorcycle: 'Moto',
    vehicle_icon_boat: 'Barco',
    vehicle_icon_pickup: 'Pickup',
    vehicle_edit_kms: 'Editar Kms',
    vehicle_edit_not_allowed: 'Não tem permissões para editar o veículo.',
    vehicle_without_position: 'Veículo sem posição',
    vehicle_associate_geofences: 'Associar Cerca Eletrônica / Rota / Marco',
    vehicle_form_category_placeholder: 'Seleccionar Ícone',
    speed_limit: 'Limite de Velocidade',
    unidentified_driver_duration: 'Duração Mínima (minutos)',
    geofences_title: 'Cercas Eletrônicas',
    geofence_name: 'Nome',
    geofence_description: 'Descrição',
    geofence_speed_Limit: 'Limite de Velocidade (Km/h)',
    geofences_type_geofences: 'Cercas Eletrônicas:',
    geofences_type_linegeofences: 'Corredores:',
    linegeofences: 'Corredores',
    geofences_type_pois: 'Marcos:',
    geofence_icon_square: 'Quadrado',
    geofence_icon_triangle: 'Triângulo',
    geofence_icon_airport: 'Aeroporto',
    geofence_icon_aquarium: 'Aquário',
    geofence_icon_attraction: 'Miradouro',
    geofence_icon_barrier: 'Barreira',
    geofence_icon_building_alt1: 'Edifício Alto',
    geofence_icon_building: 'Edifício',
    geofence_icon_car_rental: 'Rent a Car',
    geofence_icon_car_repair: 'Oficina',
    geofence_icon_castle: 'Castelo',
    geofence_icon_cemetery: 'Cemitério',
    geofence_icon_charging_station: 'Estação de Carregamento',
    geofence_icon_circle: 'Circulo',
    geofence_icon_city: 'Cidade',
    geofence_icon_embassy: 'Bandeira',
    geofence_icon_fuel: 'Posto de Gasolina',
    geofence_icon_home: 'Casa',
    geofence_icon_industry: 'Industria',
    geofence_icon_information: 'Informação',
    geofence_icon_marker: 'Marco',
    geofence_icon_marker_stroked: 'Marco',
    geofence_icon_parking: 'Parque',
    geofence_icon_parking_garage: 'Parque Coberto',
    geofence_icon_ranger_station: 'Bombeiros',
    geofence_icon_recycling: 'Reciclagem',
    geofence_icon_residential_community: 'Bairro',
    geofence_icon_star: 'Estrela',
    geofence_icon_town: 'Vila',
    geofence_icon_town_hall: 'Câmara Municipal',
    geofence_icon_village: 'Aldeia',
    geofence_icon_warehouse: 'Armazem',
    geofence_icon_waste_basket: 'Caixote do Lixo',
    geofence_icon_windmill: 'Moinho',
    geofence_edit_title: 'Editar Zona',
    geofence_form_name: 'Nome',
    geofence_form_description: 'Descrição',
    geofence_form_icon: 'Icon',
    geofence_form_color: 'Cor',
    geofence_form_options: 'Opções',
    geofence_form_speedlimit: 'Limite de Velocidade (Km/h)',
    geofence_form_distance: 'Distância Máxima',
    geofence_form_distance_apply_to_all: 'Aplicar a distância em todos os marcos.',
    geofence_form_color_apply_to_all: 'Aplicar a cor em todos os marcos.',
    groups: 'Grupos',
    group_edit: 'Editar Grupo',
    group_add: 'Adicionar Grupo',
    group_name: 'Nome',
    group_form_name: 'Nome',
    group_updated: 'Grupo actualizado com sucesso!',
    group_deleted: 'O Grupo foi apagado.',
    group_delete_info: 'Pretende apagar o grupo ',
    group_delete_title: 'Apagar Grupo',
    group_edit_confirm: 'Confirmar',
    group_edit_cancel: 'Cancelar',
    group_created: 'Grupo criado com sucesso!',
    group_select_vehicles_placeholder: 'Selecionar Veículos',
    group_select_drivers_placeholder: 'Selecionar Motoristas',
    group_select_geofences_placeholder: 'Selecionar Cercas Eletrônicas',
    group_select_pois_placeholder: 'Selecionar Marcos',
    group_select_linegeofences_placeholder: 'Selecionar corredores',
    group_add_not_allowed: 'Não tem permissões para criar grupos.',
    group_edit_not_allowed: 'Não tem permissões para editar grupos.',
    group_delete_not_allowed: 'Não tem permissões para apagar grupos.',
    drivers: 'Motoristas',
    driver_name: 'Nome',
    driver_uniqueId: 'Chave RFID',
    driver_email: 'E-Mail',
    driver_phone: 'Telemóvel',
    driver_notes: 'CPF',
    driver_add: 'Adicionar Motorista',
    driver_edit: 'Editar',
    driver_edit_title: 'Editar Motorista',
    driver_delete: 'Apagar',
    driver_form_cancel: 'Cancelar',
    driver_form_confirm: 'Confirmar',
    driver_deleted: 'O Motorista foi apagado.',
    driver_delete_info: 'Pretende apagar o motorista ',
    driver_delete_title: 'Apagar Motorista',
    driver_created: 'Motorista criado com sucesso!',
    driver_updated: 'Motorista actualizado com sucesso!',
    driver_add_not_allowed: 'Não tem permissões para criar motoristas.',
    driver_edit_not_allowed: 'Não tem permissões para editar motoristas.',
    driver_delete_not_allowed: 'Não tem permissões para apagar motoristas.',
    driver_uniqueid_create: 'Gerar',
    driver_uniqueid_create_label: 'Gerar identificador',
    driver: 'Motorista',
    reports: 'Relatórios',
    automatic_reports: 'Relatórios automáticos',
    inactive_vehicles_report_description: 'Relatório enviado sempre que existem veículos sem comunicar há mais de 72 horas',
    activate_automatic_trip_report: 'Relatório de viagens - Activar relatório automático',
    activate_automatic_location_report: 'Relatório de posições - Activar relatório automático',
    activate_automatic_zone_report: 'Relatório de zonas - Activar relatório automático',
    activate_automatic_speeding_report: 'Relatório de excesso de velocidade - Activar relatório automático',
    activate_automatic_refueling_report: 'Relatório de abastecimentos - Activar relatório automático',
    activate_automatic_fueldrop_report: 'Relatório de perda de combustível - Activar relatório automático',
    activate_automatic_activity_report: 'Relatório de actividade - Activar relatório automático',
    activate_automatic_events_report: 'Relatório de eventos - Activar relatório automático',
    activate_automatic_kms_report: 'Relatório de Kms - Activar relatório automático',
    report_periodicity_daily: 'Diariamente',
    report_periodicity_weekly: 'Semanalmente',
    report_periodicity_monthly: 'Mensalmente',
    report_by_group: 'Receber um email para cada grupo de veículos',
    uniqueid_duplicated: 'Chave RFID duplicada!',
    pois_title: 'Marcos',
    add: 'Adicionar',
    edit: 'Editar',
    delete: 'Apagar',
    name_required: 'O campo nome é obrigatório',
    email_format_invalid: 'Por favor indique um e-mail válido',
    email_or_phone_required: 'Pelo menos um destes campos deverá ser preenchido com um valor válido.',
    users: 'Usuários',
    user_edit: 'Editar Usuário',
    user_add: 'Adicionar Usuário',
    user_name: 'Nome',
    user_email: 'Email',
    user_phone: 'Telemóvel',
    user_language: 'Idioma',
    user_timezone: 'Fuso horário',
    user_type: 'Perfil',
    user_password: 'Palavra-chave',
    user_form_type_placeholder: 'Selecionar Perfil',
    user_form_manage_devices: 'Permitir alterar definições dos veículos.',
    user_deleted: 'O Usuário foi apagado.',
    user_delete_info: 'Pretende apagar o usuário ',
    user_delete_title: 'Apagar Usuário',
    user_created: 'Usuário criado com sucesso!',
    user_updated: 'Usuário actualizado com sucesso!',
    user_create_not_allowed: 'Não tem permissões para criar usuários.',
    user_edit_not_allowed: 'Não tem permissões para editar usuários.',
    user_duplicated_entry: 'Usuário já existe.',
    temporary_links: 'Acessos Temporários',
    expiration_date: 'Data de Expiração',
    user_temporary_link: 'Acesso',
    temporary_link_add: 'Criar Acesso Temporário',
    temporary_link_edit: 'Editar Acesso Temporário',
    temporary_link_created: 'Acesso temporário criado com sucesso!',
    temporary_link_create_not_allowed: 'Não tem permissões para criar acessos temporários.',
    temporary_link_deleted: 'O acesso temporário foi apagado.',
    temporary_link_delete_info: 'Pretende apagar o acesso temporário  ',
    temporary_link_delete_title: 'Apagar Acesso Temporário',
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    sunday: 'Domingo',
    full: 'Completo',
    partial: 'Parcial',
    selectStartTime: 'Seleccionar Horas de Inicio',
    selectEndTime: 'Seleccionar Horas de Fim',
    edit_vehicle_kms_warning: 'Neste veículo o calculo dos Kms é feito directamente no equipamento, para actualizar os Kms é necessário alterar a configuração do mesmo. Por favor contacte o suporte.'
  },
  geofence: {
    showGeofences: 'Ver Cercas Eletrônicas',
    geofence_name: 'Nome',
    geofence_edit: 'Editar',
    geofence_delete: 'Apagar',
    geofence_deleted: 'A cerca eletrônica foi apagada',
    geofence_delete_info: 'Pretende apagar a cerca eletrônica ',
    geofence_delete_title: 'Apagar Cerca Eletrônica',
    geofence_edit_title: 'Editar Cerca Eletrônica',
    geofence_edit_name: 'Por favor indique o nome da cerca eletrônica...',
    geofence_edit_confirm: 'Confirmar',
    geofence_edit_cancel: 'Cancelar',
    geofence_edit_canceled: 'Edição cancelada',
    geofence_edited: 'Cerca Eletrônica editada com sucesso!',
    poi_delete_info: 'Pretende apagar o Marco ',
    poi_delete_title: 'Apagar Marco',
    poi_edited: 'Marco editado com sucesso!',
    poi_deleted: 'Marco apagado.',
    poi_edit_title: 'Editar Marco',
    poi_edit_name: 'Por favor indique o nome do Marco.',
    linegeofence_delete_info: 'Pretende apagar o corredor ',
    linegeofence_delete_title: 'Apagar Corredor',
    linegeofence_edited: 'Corredor editado com sucesso!',
    linegeofence_deleted: 'Corredor foi apagado',
    linegeofence_edit_title: 'Editar Corredor',
    linegeofence_edit_name: 'Por favor indique o nome do corredor...',
    searchGeofence: 'Procurar Cerca Eletrônica',
    edit_geofence: 'Editar',
    delete_geofence: 'Apagar'
  },
  report: {
    selector_search: 'Pesquisa',
    select_vehicles: 'Selecionados',
    select_vehicles_placeholder: 'Veículos',
    select_groups: 'Selecionados',
    select_groups_placeholder: 'Grupos',
    select_geofences: 'Selecionadas',
    select_geofences_placeholder: 'Cercas Eletrônicas',
    select_period: 'Selecionar período',
    date_start: 'Data de início',
    date_end: 'Data de fim',
    generate_report: 'Gerar relatório',
    period: 'Período',
    validate_period: 'Por favor selecione o período.',
    select_all: 'Todos'
  },
  layout: {
    deviceOnline: 'Dispositivo Online',
    deviceMoving: 'Veículo em Movimento',
    deviceStopped: 'Veículo Parado',
    ignitionOff: 'Ignição Desligada',
    ignitionOn: 'Ignição Ligada',
    deviceOverspeed: 'Excesso de Velocidade',
    geofenceEnter: 'Entrada em Cerca Eletrônica',
    geofenceExit: 'Saída de Cerca Eletrônica',
    newVersion: 'Está disponível uma nova versão desta aplicação. Carregue aqui para actualizar.'
  }
}
