const getters = {
  viewSpeedAlerts: state => state.settings.viewSpeedAlerts,
  viewEventsOnRoute: state => state.settings.viewEventsOnRoute,
  viewIdlePoints: state => state.settings.viewIdlePoints,
  allTripsSource: state => state.map.allTripsSource,
  allTripsArrowsSource: state => state.map.allTripsArrowsSource,
  popupOpened: state => state.popupOpened,
  dataContainerTabActive: state => state.dataContainerTabActive,
  showWaze: state => state.map.showWaze,
  showTraffic: state => state.map.showTraffic,
  markerTypes: state => state.markerTypes,
  userChangePass: state => state.user.userChangePass,
  labelColor: state => state.settings.labelColor,
  labelTextSize: state => state.settings.labelTextSize,
  labelPOITextSize: state => state.map.labelPOITextSize,
  showFullDate: state => state.settings.showFullDate,
  showStopDate: state => state.settings.showStopDate,
  search: state => state.transient.search,
  totalDistance: state => state.transient.totalDistance,
  user: state => state.user.user,
  avatar: state => state.user.user.attributes && state.user.user.attributes.avatar,
  name: state => state.user.user.name,
  map: state => state.map,
  devices: state => state.user.devices,
  loading: state => state.transient.loading,
  loadingEvents: state => state.transient.loadingEvents,
  historyMode: state => state.transient.historyMode,
  routeFetched: state => state.transient.routeFetched,
  minPos: state => state.map.minPos,
  maxPos: state => state.map.maxPos,
  isPlaying: state => state.transient.isPlaying,
  alerts: state => state.user.alerts,
  events: state => state.transient.events,
  dataLoaded: state => state.transient.dataLoaded,
  devicesLoaded: state => state.transient.devicesLoaded,
  groups: state => state.user.groups,
  drivers: state => state.user.drivers,
  driverUniqueIds: state => state.user.driverUniqueIds,
  maintenances: state => state.user.maintenances,
  commands: state => state.user.commands,
  users: state => state.user.users,
  geofences: state => state.user.geofences,
  unreadItems: state => state.unreadItems,
  showLabels: state => state.settings.showLabels,
  showPOIsLabels: state => state.settings.showPOIsLabels,
  reducePoiSize: state => state.settings.reducePoiSize,
  currentTime: state => state.currentTime,
  trips: state => state.transient.trips,
  stops: state => state.transient.stops,
  initialized: state => state.transient.initialized,
  showGeofences: state => state.map.showGeofences,
  showSigns: state => state.map.showSigns,
  showPOIs: state => state.map.showPOIs,
  tableCollapsed: state => state.map.tableCollapsed,
  vehicles3dEnabled: state => state.settings.vehicles3d,
  followVehicle: state => state.map.followVehicle,
  followVehicleEnabled: state => state.settings.followVehicle,
  deviceById: (state, getters) => (id) => {
    return getters.devices.find(d => d.id === id)
  },
  deviceByName: (state, getters) => (name) => {
    return getters.devices.find(d => d.name === name)
  },
  zoom: state => state.map.zoom,
  center: state => state.map.center,
  alertsSearchPeriod: state => state.user.user.attributes.alertsSearchPeriod,
  mapType: state => state.map.mapType,
  mapStyle: state => state.map.mapStyle,
  percentage: state => state.transient.percentage
}

export default getters
