import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'

export const awsConfig = {
  aws_project_region: 'eu-west-3',
  aws_cognito_identity_pool_id: 'eu-west-3:a9b51fc7-a3f9-4fec-b59e-027f766bc516',
  aws_cognito_region: 'eu-west-3',
  aws_user_pools_id: 'eu-west-3_3zjuFkIv8',
  aws_user_pools_web_client_id: '2ml2d0h1qk7q614qc3bclg2alj',
  oauth: {
    redirectSignIn: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/',
    redirectSignOut: location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/',
    domain: 'fleetmap.auth.eu-west-3.amazoncognito.com',
    scope: [
      'email',
      'openid',
      'aws.cognito.signin.user.admin'
    ],
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  authenticationFlowType: 'USER_PASSWORD_AUTH'
}

Amplify.configure(awsConfig)
Auth.configure(awsConfig)

