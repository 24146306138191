export const positionsSource = 'positions'
export const routeSlotLength = 6
export const cdnUrl = 'https://d7eumezdkbwin.cloudfront.net'
export const cdnPoiImages = 'https://d1qklwe1xqavez.cloudfront.net'
export const spriteUrl = cdnUrl + '/sprite/14/sprite'
export const vehIconsUrl = cdnUrl + '/vehIcons'
export const mapboxAccessToken = new Date().getTime() % 2 ? process.env.MAPBOX_TOKEN : process.env.MAPBOX_TOKEN2
export const detailedZoom = 13
export const stopZoom = 18
export const maxRouteEvents = 500

export const timezones = [
  { value: 'Europe/Lisbon', text: 'Europe/Lisbon' },
  { value: 'Europe/Madrid', text: 'Europe/Madrid' },
  { value: 'Africa/Luanda', text: 'Africa/Luanda' },
  { value: 'Africa/Maputo', text: 'Africa/Maputo' },
  { value: 'Africa/Casablanca', text: 'Africa/Casablanca' },
  { value: 'America/Cuiaba', text: 'America/Cuiabá' },
  { value: 'America/Santiago', text: 'America/Santiago' },
  { value: 'America/Sao_Paulo', text: 'America/São Paulo' },
  { value: 'Asia/Qatar', text: 'Asia/Qatar' }
]
