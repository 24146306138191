<template>
  <div>
    <div v-if="device && device.position && Object.keys(device.position.attributes).includes('out2')" v-loading="loading">
      <el-tooltip
        :content="$t('Enviar comando?')"
        placement="bottom"
      >
        <el-button circle plain size="small" @click="clickActuator">
          <i class="fas fa-paper-plane-top"></i>
        </el-button>
      </el-tooltip>
    </div>
    <div v-if="device && device.attributes && device.attributes.commandGetPosition" v-loading="loading">
      <el-tooltip
        :content="$t('Enviar pedido de posição')"
        placement="bottom"
      >
        <el-button circle plain size="small" @click="click(true)">
          <i class="fas fa-paper-plane-top"></i>
        </el-button>
      </el-tooltip>
    </div>
    <div v-if="device && device.attributes && device.attributes.internalNotes === 'Camera'">
      <table>
        <tr><td>
          <div>
            <el-tooltip
              :content="$t('Enviar pedido de foto camera 1')"
              placement="bottom"
            >
              <el-button circle plain size="mini" @click="clickPhoto(1)">
                <i class="fa-regular fa-camera"></i>
              </el-button>
            </el-tooltip>
          </div></td><td>
          <div>
            <el-tooltip
              :content="$t('Enviar pedido de foto camera 2')"
              placement="bottom"
            >
              <el-button circle plain size="mini" @click="clickPhoto(2)">
                <i class="fa-regular fa-camera"></i>
              </el-button>
            </el-tooltip>
          </div></td></tr></table>
    </div>
  </div>
</template>

<script>

import axios from 'axios'
import { getServerHost } from '@/api'

export default {
  name: 'ActuatorButton',
  props: {
    device: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async clickActuator() {
      try {
        const command = this.device.position.attributes.out2 ? this.$t('DESACTIVAR') : this.$t('ACTIVAR')
        await this.$confirm(`Desea enviar el comando ${command} al actuador?`)
      } catch (e) {
        return
      }
      try {
        const data = `setdigout ?${this.device.position.attributes.out2 ? '0' : '1'}`
        await axios.post(`https://${getServerHost()}/api/commands/send`, {
          deviceId: this.device.id, type: 'custom', attributes: { data }, description: 'vue'
        }, { withCredentials: true }).then(r => r.data)
        await this.$alert('Pedido enviado.')
      } catch (e) {
        await this.$alert(e.message)
      }
    },
    async click() {
      this.loading = true
      try {
        await axios.post('/pinmeapi/commands', this.device, { withCredentials: true })
        await this.$alert(`Comando enviado com sucesso`)
      } catch (e) {
        await this.$alert(e.message)
      }
      this.loading = false
    },
    async clickPhoto(camera) {
      try {
        await this.$confirm(`Desea enviar un pedido de video para la camera ${camera}?`)
      } catch (e) {
        return
      }
      try {
        const data = `camreq:0,${camera},${Math.round((new Date().getTime() + 10000) / 1000)},5,15.236.29.57,5232`
        await axios.post(`https://${getServerHost()}/api/commands/send`, {
          deviceId: this.device.id, type: 'custom', attributes: { data }, description: 'vue'
        }, { withCredentials: true }).then(r => r.data)
        await this.$alert('Pedido enviado.')
      } catch (e) {
        await this.$alert(e.message)
      }
    }
  }
}
</script>

<style scoped>

</style>
