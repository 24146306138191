<template>
  <div v-if="showSideBar" style="width:64px; height:100%">
    <el-menu style="height: 100%" collapse default-active="1" router>
      <el-menu-item index="/settings/vehicles">
        <i class="fas fa-car side-bar-icon"></i>
        <span slot="title">{{ $t('settings.vehicles') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/groups">
        <i class="fas fa-grip-horizontal side-bar-icon"></i>
        <span slot="title">{{ $t('settings.groups') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/drivers">
        <i class="fas fa-address-card side-bar-icon"></i>
        <span slot="title">{{ $t('settings.drivers') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/users">
        <i class="fas fa-user-friends side-bar-icon"></i>
        <span slot="title">{{ $t('settings.users') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/links">
        <i class="fas fa-user-clock side-bar-icon"></i>
        <span slot="title">{{ $t('settings.temporary_links') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/geofence">
        <i class="fas fa-map-marked side-bar-icon"></i>
        <span slot="title">{{ $t('settings.zone') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/alerts">
        <i class="fas fa-bell side-bar-icon"></i>
        <span slot="title">{{ $t('settings.alerts') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/maintenance">
        <i class="fas fa-wrench side-bar-icon"></i>
        <span slot="title">{{ $t('settings.maintenance') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/reports">
        <i class="fas fa-sticky-note side-bar-icon"></i>
        <span slot="title">{{ $t('route.scheduler') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/map">
        <i class="fas fa-map side-bar-icon"></i>
        <span slot="title">{{ $t('settings.map') }}</span>
      </el-menu-item>
      <el-menu-item index="/settings/profile">
        <i class="fas fa-user side-bar-icon"></i>
        <span slot="title">{{ $t('navbar.profile') }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { schedulerIsActive } from '@/utils/modules'
import store from '@/store'

export default {
  name: 'SettingsSideBar',
  computed: {
    showSideBar() {
      return this.$route.path.includes('settings')
    }
  },
  methods: {
    manageSchedules() {
      return schedulerIsActive(store.getters.user)
    }
  }
}
</script>

<style scoped>
  .side-bar-icon {
    margin-left: 4px;
  }
</style>
