import 'mapbox-gl/dist/mapbox-gl.css'
import 'normalize.css/normalize.css'
import Vue from 'vue'
import store from './store'
import router from './router'
import './routeInterceptor'
import * as filters from './filters' // global filters
import VueLogger from 'vuejs-logger'
import VueStatic from 'vue-static'
import i18n, { getLanguage } from './lang'
import LoadScript from 'vue-plugin-load-script'
import * as event from './events'
import './amplify'
import VueTimers from 'vue-timers'
import * as partner from './utils/partner'
import * as Sentry from '@sentry/vue'
import Element from 'element-ui'
import { CaptureConsole } from '@sentry/integrations'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: process.env.SENTRY_DSN,
    disablePerformance: true,
    integrations: [
      /* new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),*/
      new CaptureConsole({ levels: ['error'] }),
      new Sentry.Replay()
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    tracesSampleRate: 0.1
  })
}

import ElTableInfiniteScroll from 'el-table-infinite-scroll'

Vue.directive('el-table-infinite-scroll', ElTableInfiniteScroll)

export const sharedData = {
  positions: null,
  chartLabels: null,
  chartData: null,
  chartDataIgnitionOff: null,
  chartDataIdle: null,
  chartDataFuelSensor: null,
  chartDataRPM: null,
  chartDataEvents: null,
  positionIndex: null,
  setPositionIndex(arr) {
    this.positionIndex = arr
  },
  getPositionIndex() {
    return this.positionIndex
  },
  setChartData(data, dataFuelSensor, dataRPM, dataEvents, dataIgnitionOff, temperatures, dataIdle) {
    this.chartData = data
    this.chartDataFuelSensor = dataFuelSensor
    this.chartDataRPM = dataRPM
    this.chartDataEvents = dataEvents
    this.chartDataIgnitionOff = dataIgnitionOff
    this.chartDataTemperature = temperatures
    this.chartDataIdle = dataIdle
  },
  getChartLabels() {
    return this.chartLabels
  },
  getChartData() {
    return this.chartData
  },
  getChartDataIgnitionOff() {
    return this.chartDataIgnitionOff
  },
  getChartDataIdle() {
    return this.chartDataIdle
  },
  getChartDataFuelLevel() {
    return this.chartDataFuelSensor
  },
  getChartDataRPM() {
    return this.chartDataRPM
  },
  getChartDataEvents() {
    return this.chartDataEvents
  },
  setPositions(positions) {
    this.positions = positions
  },
  getPositions() {
    return this.positions
  },
  getTempChartData() {
    return this.chartDataTemperature
  }
}

const App = () => import('./App')

console.log('app starting...', process.env)

Vue.config.errorHandler = (err, vm, info) => {
  // Log properties passed to the component if there are any
  if (vm.$options.propsData) {
    console.log('Props passed to component', vm.$options.propsData)
  }

  // Emit component name and also the lifecycle hook the error occurred in if present
  let infoMessage = `Error in component: <${vm.$options.name} />\n`
  if (info) {
    infoMessage += `Error occurred during lifecycle hook: ${info}\n`
  }
  // This puts the additional error information in the Telemetry Timeline
  console.log(infoMessage)
  console.error(err)
}

Vue.use(LoadScript)

const isProduction = process.env.NODE_ENV !== 'development'
const options = {
  isEnabled: true,
  logLevel: isProduction ? 'info' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, options)
Vue.config.lang = getLanguage().slice(0, 2).toUpperCase()
console.log('lang', Vue.config.lang)

import pt from 'element-ui/lib/locale/lang/pt'
import es from 'element-ui/lib/locale/lang/es'
import en from 'element-ui/lib/locale/lang/en'
import fr from 'element-ui/lib/locale/lang/fr'

switch (Vue.config.lang) {
  case 'FR':
    Vue.use(Element, { locale: fr })
    break
  case 'ES':
    Vue.use(Element, { locale: es })
    break
  case 'EN':
    Vue.use(Element, { locale: en })
    break
  default:
    Vue.use(Element, { locale: pt })
}

export const serverBus = new Vue()

export let newServiceWorker

window.OneSignal = window.OneSignal || []
window.OneSignal.push(() => {
  Vue.$log.info('onesignal appid', partner.getOneSignalAppId())
  window.OneSignal.init({
    appId: partner.getOneSignalAppId(),
    allowLocalhostAsSecureOrigin: process.env.ENV !== 'production'
  })
})

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    if (registrations.length === 0) {
      navigator.serviceWorker.register('/OneSignalSDKWorker.js').then(r => Vue.$log.debug('registered service worker for the first time', r))
    }
    for (const reg of registrations) {
      reg.addEventListener('updatefound', () => {
        Vue.$log.debug('A wild service worker has appeared in reg.installing!')
        newServiceWorker = reg.installing
        newServiceWorker.addEventListener('statechange', () => {
          console.log('new state', newServiceWorker.state)
          // Has network.state changed?
          if (newServiceWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              serverBus.$emit('updateAvailable')
            }
          }
        })
      })
    }
  })

  navigator.serviceWorker.addEventListener('controllerchange', (e) => {
    Vue.$log.warn(navigator.serviceWorker, e)
  })
} else {
  Vue.$log.warn('no service Worker support, weird browser...')
}

const moment = require('moment')
require('moment/locale/pt')
require('moment/locale/es')
require('moment/locale/fr')
require('moment/locale/en-gb')// 'fr'
const lang = getLanguage().slice(0, 2)
Vue.$log.debug('setting moment locale to', lang)
moment.locale(lang)
Vue.use(require('vue-moment'), { moment })

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

Vue.use(VueStatic, {
  namespaced: true
})

Vue.use(VueTimers)

Vue.$log.debug('starting main instance...', location.href)

export const vm = new Vue({
  el: '#app',
  data() {
    return {
      loading: false,
      loadingRoutes: false,
      routeMinDate: Vue.moment().startOf('day').toDate(),
      routeMaxDate: new Date(),
      devices: [],
      geofences: [],
      currentDevice: null,
      lazyLoad: false,
      distance: 0,
      loggedIn: false,
      reportData: []
    }
  },
  created() {
    serverBus.$on(event.newEventReceived, () => {
      store.dispatch('incUnreadItems').then(() => {})
    })
  },
  static() {
    return {
      vehicles3d: null,
      currentFeature: null,
      markers: {},
      map: null,
      positionsSource: {
        type: 'FeatureCollection',
        features: []
      },
      geofencesSource: {
        type: 'FeatureCollection',
        features: []
      },
      eventsSource: {
        type: 'FeatureCollection',
        features: []
      }
    }
  },
  methods: {
    device(deviceId) {
      return this.devices.find(e => e.id === deviceId)
    },
    reset: function() {
      for (const i in this.$static.markers) {
        // noinspection JSUnfilteredForInLoop
        console.log('deleting static marker ', this.$static.markers[i])
        // noinspection JSUnfilteredForInLoop
        this.$static.markers[i].remove()
        // noinspection JSUnfilteredForInLoop
        delete this.$static.markers[i]
      }
      this.$static.markers = {}
      this.$log.warn('removing sources')
      this.$static.positionsSource.features = []
      this.$static.geofencesSource.features = []
      this.$static.eventsSource.features = []
      this.$data.historyMode = false
      this.$log.debug('done.')
      this.devices = []
      this.$log.debug('reset end')
    }
  },
  router: router,
  store,
  i18n,
  render: h => {
    try {
      return h(App)
    } catch (e) {
      console.error(e)
      return e
    }
  }
})

import('./common')

