import router from './router'

import Vue from 'vue'
import store from './store'

const whiteList = ['/login', '/auth-redirect', '/googlelogin/', '/register', '/changePassword', '/wait'] // no redirect whitelist

let firstLoad = true

export async function routerBeforeEach(next, to) {
  const hasToken = store.getters.user.email !== ''

  if (hasToken && !window.location.pathname.includes('/googlelogin/')) {
    if (to.path === '/login') {
      Vue.$log.info('redirecting to /')
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      Vue.$log.info('skipping auth', to.path)
      next()
    } else if (whiteList.indexOf(window.location.pathname) !== -1) {
      const forcePath = window.location.pathname + window.location.search
      Vue.$log.info('forcing pathname', to.path, forcePath)
      next(forcePath)
    } else {
      Vue.$log.info('redirecting to login', to.path)
      next(`/login?redirect=${to.path}`)
    }
  }
}

router.beforeEach(async(to, from, next) => {
  if (firstLoad) {
    firstLoad = false
    next('wait?redirect=' + encodeURIComponent(`${to.fullPath}`))
  } else {
    await routerBeforeEach(next, to)
  }
})

