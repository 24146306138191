import store from '@/store'
import { vm } from '@/main'
import circle from '@turf/circle'

export default {
  geofencesFill: {
    id: 'geofences-fill',
    type: 'fill',
    source: 'geofences',
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': 0.4
    },
    layout: { visibility: store && store.state.map.showGeofences ? 'visible' : 'none' },
    filter: ['all',
      ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
      ['==', ['get', 'type'], 'GEOFENCE'],
      ['==', ['get', 'fill'], true],
      ['==', ['get', 'visible'], true]
    ]
  },
  geofences: {
    id: 'geofences',
    type: 'line',
    source: 'geofences',
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 4,
      'line-opacity': 0.4
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: store && store.state.map.showGeofences ? 'visible' : 'none'
    },
    filter: ['all',
      ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
      ['==', ['get', 'type'], 'GEOFENCE'],
      ['==', ['get', 'visible'], true]
    ]
  },
  geofencesLabels: {
    id: 'geofences-labels',
    type: 'symbol',
    source: 'geofences',
    layout: {
      'text-field': '{title}',
      'text-font': ['Arial Unicode MS Bold', 'Open Sans Regular'],
      visibility: store && store.state.map.showGeofences ? 'visible' : 'none'
    },
    filter: ['all',
      ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
      ['==', ['get', 'type'], 'GEOFENCE'],
      ['==', ['get', 'visible'], true]
    ],
    paint: {
      'text-halo-width': 2,
      'text-color': 'white',
      'text-halo-color': 'black'
    }
  },
  geofencesLines: {
    id: 'geofences-lines',
    type: 'line',
    source: 'geofences',
    paint: {
      'line-color': ['get', 'color'],
      'line-width': 4,
      'line-opacity': 0.4
    },
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
      visibility: store && store.state.map.showLineGeofences ? 'visible' : 'none'
    },
    filter: ['all',
      ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
      ['==', ['get', 'type'], 'LINE'],
      ['==', ['get', 'visible'], true]
    ]
  },
  geofencesLinesLabels: {
    id: 'geofences-lines-labels',
    type: 'symbol',
    source: 'geofences',
    layout: {
      'text-field': ['get', 'title'],
      visibility: store && store.state.map.showGeofences ? 'visible' : 'none'
    },
    filter: ['all',
      ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
      ['==', ['get', 'type'], 'LINE'],
      ['==', ['get', 'visible'], true]
    ]
  },
  pois: (store) => {
    return {
      id: 'pois',
      type: 'symbol',
      source: 'geofences',
      filter: ['all',
        ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
        ['==', ['get', 'type'], 'POI'],
        ['==', ['get', 'visible'], true]
      ],
      paint: {
        'text-color': ['concat', '#', ['get', 'color']],
        'text-halo-width': 1,
        'text-halo-blur': 1,
        'text-halo-color': ['case',
          ['==', ['get', 'color'], 'FFFFFF'], 'black',
          ['==', ['get', 'color'], 'F7F9F7'], 'black',
          'white']
      },
      layout: {
        'text-font': ['Arial Unicode MS Bold', 'Open Sans Regular'],
        'text-allow-overlap': !!store.state.map.poiAllowOverlap,
        'icon-allow-overlap': !!store.state.map.poiAllowOverlap,
        'icon-anchor': 'center',
        'text-field': store && store.state.settings.showPOIsLabels ? ['get', 'title'] : '',
        visibility: store && store.state.map.showPOIs ? 'visible' : 'none',
        'text-justify': 'auto',
        'text-offset': [0, 2],
        'icon-image': ['concat', ['get', 'icon'], ['get', 'color']],
        'icon-size': store && store.state.settings.reducePoiSize ? ['interpolate', ['linear'], ['zoom'], 10, 0.4, 15, 1] : 1
      }
    }
  },
  poiCircles: {
    id: 'poiCircles',
    type: 'line',
    source: 'geofences',
    filter: ['all',
      ['any', true, ['in', 'filter', ['downcase', ['get', 'title']]]],
      ['==', ['get', 'type'], 'POI'],
      ['==', ['get', 'visible'], true]
    ],
    layout: {
      visibility: store && store.state.map.showPOIs &&
        store.state.map.viewPoiCircles ? 'visible' : 'none'
    },
    paint: {
      'line-color': ['concat', '#', ['get', 'color']]
    }
  },
  getFeatureGeojson(item) {
    const wkt = item.area
    let geojson
    if (!item.area) { return undefined }
    if (item.area.startsWith('POLYGON')) {
      geojson = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[]]
        },
        properties: {
          id: item.id,
          title: item.name,
          description: item.description,
          icon: '',
          color: item.attributes.color ? item.attributes.color : '#3232b4',
          fill: Object.prototype.hasOwnProperty.call(item.attributes, 'fill') ? item.attributes.fill : true,
          type: 'GEOFENCE',
          visible: item.visible
        }
      }
      const str = wkt.substring('POLYGON(('.length, wkt.length - 2)
      const coord_list = str.split(',')
      for (const i in coord_list) {
        const coord = coord_list[i].trim().split(' ')
        geojson.geometry.coordinates[0].push([parseFloat(coord[1]), parseFloat(coord[0])])
      }
    } else if (item.area.startsWith('LINE')) {
      geojson = {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        },
        properties: {
          id: item.id,
          title: item.name,
          description: item.description,
          icon: '',
          color: item.attributes.color ? item.attributes.color : '#3232b4',
          fill: false,
          type: 'LINE',
          visible: item.visible
        }
      }
      const str = wkt.substring('LINESTRING('.length + 1, wkt.length - 1)
      const coord_list = str.split(',')
      for (const i in coord_list) {
        const coord = coord_list[i].trim().split(' ')
        geojson.geometry.coordinates.push([parseFloat(coord[1]), parseFloat(coord[0])])
      }
    } else if (item.area.startsWith('CIRCLE')) {
      geojson = {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: []
        },
        properties: {
          id: item.id,
          title: item.name,
          description: item.description,
          icon: item.attributes.icon || 'marker',
          color: item.attributes.color ? item.attributes.color.replace('#', '') : '3232b4',
          fill: '',
          type: 'POI',
          visible: item.visible
        }
      }
      const str = wkt.substring('CIRCLE ('.length, wkt.indexOf(','))
      const coord = str.trim().split(' ')

      geojson.geometry.coordinates = [parseFloat(coord[1]).toFixed(6), parseFloat(coord[0]).toFixed(6)]
    }
    return geojson
  },
  getFeatureCircleGeojson(item) {
    const wkt = item.area
    const str = wkt.substring('CIRCLE ('.length, wkt.indexOf(','))
    const coord = str.trim().split(' ')
    const circleFeature = circle([parseFloat(coord[1]), parseFloat(coord[0])],
      parseInt(wkt.substring(wkt.indexOf(',') + 1, wkt.indexOf(')')).trim()) / 1000)

    circleFeature.properties.id = item.id
    circleFeature.properties.type = 'POI'
    circleFeature.properties.visible = item.visible

    return circleFeature
  },
  findFeatureById(id) {
    return vm.$static.geofencesSource.features.find(e => {
      return e.properties.id === id
    })
  }
}
