<template>
  <div v-if="showCoverage">
    <el-tooltip
      :content="`
        ${$t('Sinal GSM')}: ${signalLevel}
      `"
      placement="bottom"
    >
      <el-button circle plain size="small" @click="show=!show">
        <i
          :class="`fas fa-wifi${lowSignal ? '-slash' : ''}`"
          :style="`color: ${lowSignal ? styles.alerts : styles.success}`"
        />{{ ' ' + getOperator(position) }}
      </el-button>
    </el-tooltip>
    <div ref="coverage" style="font-size:small"></div>
  </div>
</template>

<script>
import axios from 'axios'
import styles from '../styles/element-variables.scss'
import { lowSignal, signalLevel } from '@/utils'
import mccmnc from 'mcc-mnc-list/mcc-mnc-list.json'

export default {
  name: 'GsmCoverage',
  props: {
    position: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      show: false,
      html: ''
    }
  },
  computed: {
    lowSignal() {
      return lowSignal(this.position)
    },
    signalLevel() {
      return signalLevel(this.position)
    },
    styles() {
      return styles
    },
    showCoverage() {
      return window.location.hostname === 'localhost' ||
        window.location.hostname === 'nogartel.fleetmap.io'
    }
  },
  watch: {
    async show() {
      if (this.show) {
        this.$refs.coverage.innerHTML += await axios.get(`/anatel/?lat=${this.position.latitude}&lon=${this.position.longitude}`).then(d => d.data)
      } else {
        this.$refs.coverage.innerHTML = ''
      }
    }
  },
  methods: {
    getOperator(position) {
      let mccMnc
      if (position.attributes.operator) {
        mccMnc = position.attributes.operator.toString()
      }
      if (position.network && position.network.cellTowers[0]) {
        const cellTower = position.network.cellTowers[0]
        mccMnc = cellTower.mobileCountryCode.toString() + cellTower.mobileNetworkCode.toString()
      }
      if (!mccMnc) { return '' }
      const operator = mccmnc.find(m => m.mcc.toString() + m.mnc.toString() === mccMnc)
      return operator && operator.brand
    }
  }
}
</script>

<style>
.intensidade_0 {
  color: #e9e9e9;
  font-size: 50%;
}

.intensidade_1 {
  color: #b70000;
  font-size: 50%;
}

.intensidade_2 {
  color: #b70000;
  font-size: 50%;
}

.intensidade_3 {
  color: #e7ab00;
  font-size: 50%;
}

.intensidade_4 {
  font-size: 50%;
  color: #008700;
}

.intensidade_5 {
  font-size: 50%;
  color: #008700;
}

.result th {
  text-align: center;
}
</style>
