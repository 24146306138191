<template>
  <div v-if="showSideBar" style="width:64px; height:100%">
    <el-menu style="height: 100%" collapse default-active="1" router>
      <el-menu-item v-if="map" index="/map">
        <i class="el-icon-place"></i>
        <span slot="title">{{ $t('route.map') }}</span>
      </el-menu-item>
      <a href="/legacy/index.html" target="_blank">
        <el-menu-item v-if="partnerData.legacyMap">
          <span slot="title">{{ $t('Legacy Map') }}</span>
          <i class="el-icon-map-location"></i>
        </el-menu-item>
      </a>
      <el-menu-item v-if="dashboard" @click="openDashboard">
        <i class="el-icon-menu"></i>
        <span slot="title">{{ $t('route.dashboard') }}</span>
      </el-menu-item>
      <el-menu-item v-if="reports" @click="openReports">
        <i class="el-icon-document"></i>
        <span slot="title">{{ $t('route.reports') }}</span>
      </el-menu-item>
      <a
        :href="href"
        target="_blank"
      >
        <el-menu-item v-if="user.attributes.partnerReports || partnerData.partnerReports">
          <span slot="title">{{ $t('Relatórios personalizados') }}</span>
          <i class="el-icon-tickets"></i>
        </el-menu-item>
      </a>
      <a href="/reports/schedules/" target="_blank">
        <el-menu-item v-if="manageSchedules">
          <i class="el-icon-date"></i>
          <span slot="title">{{ $t('route.scheduler') }}</span>
        </el-menu-item>
      </a>
      <a v-if="partnerData.externalReports" :href="partnerData.externalReports.url" target="_blank">
        <el-menu-item>
          <i :class="partnerData.externalReports.icon || 'el-icon-document-copy'"></i>
          <span slot="title">{{ partnerData.externalReports.title }}</span>
        </el-menu-item>
      </a>
      <el-menu-item v-if="settings" @click="openSettings">
        <i class="el-icon-setting"></i>
        <span slot="title">{{ $t('route.settings') }}</span>
      </el-menu-item>
      <el-menu-item v-if="odoo" @click="openOdoo">
        <i class="el-icon-truck"></i>
        <span slot="title">{{ $t('route.management') }}</span>
      </el-menu-item>
      <a :href="user.attributes.tacho && user.attributes.tacho.startsWith && user.attributes.tacho.startsWith('http') ? user.attributes.tacho : '/reports/tacho/'" target="_blank">
        <el-menu-item v-if="tacho">
          <span slot="title">{{ $t('Tacógrafo') }}</span>
          <i class="fas fa-digital-tachograph" style="padding: 5px"></i>
        </el-menu-item></a>
      <a href="/route-dispatch/" target="_blank">
        <el-menu-item v-if="user.attributes.token">
          <span slot="title">{{ $t('Routes') }}</span>
          <i class="fas fa-route" style="padding: 5px"></i>
        </el-menu-item></a>
      <el-menu-item v-if="manual" @click="openManual">
        <i class="el-icon-collection"></i>
        <span slot="title">{{ $t('route.manual') }}</span>
      </el-menu-item>
      <el-menu-item v-for="mi in extraMenuItems" :key="mi.title" @click="open(mi.url)">
        <i :class="mi.class"></i>
        <span slot="title">{{ $t(mi.title) }}</span>
      </el-menu-item>
      <el-menu-item @click="logout()">
        <i class="el-icon-switch-button"></i>
        <span slot="title">{{ $t('navbar.logout') }}</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import store from '@/store'
import { getPartnerData } from 'fleetmap-partners'
import {
  dashboardIsActive,
  manualIsActive,
  mapIsActive,
  odooIsActive,
  reportsIsActive,
  settingsIsActive,
  schedulerIsActive
} from '@/utils/modules'
import { Auth } from '@aws-amplify/auth'
import { tachoIsActive } from '@/utils/modules'

export default {
  computed: {
    ...mapGetters(['user']),
    partnerData() {
      return getPartnerData()
    },
    extraMenuItems() {
      return Object.keys(this.user.attributes).filter(k => k.startsWith('menuItem')).map(i => {
        const values = this.user.attributes[i].split('|')
        return {
          class: values[0],
          title: values[1],
          url: values[2]
        }
      })
    },
    tacho() {
      return tachoIsActive(this.user)
    },
    href() {
      const userUrl = this.user.attributes.partnerReports &&
        this.user.attributes.partnerReports.startsWith &&
        this.user.attributes.partnerReports.startsWith('/')
      if (userUrl) {
        return this.user.attributes.partnerReports
      }
      if (this.user.attributes.partnerReports) {
        return '/reports/partnerReports/'
      }
      return this.partnerData.partnerReports
    },
    manageSchedules() {
      return schedulerIsActive(store.getters.user)
    },
    odoo() {
      return odooIsActive(store.getters.user)
    },
    manual() {
      return manualIsActive()
    },
    dashboard() {
      return dashboardIsActive(store.getters.user)
    },
    reports() {
      return reportsIsActive(store.getters.user)
    },
    settings() {
      return settingsIsActive(store.getters.user)
    },
    map() {
      return mapIsActive(store.getters.user)
    },
    showSideBar() {
      return !this.$route.path.includes('settings')
    }
  },
  methods: {
    open: (url) => window.open(url),
    openDashboard() {
      window.open('/dashboard')
    },
    openReports() {
      window.open('/reports/')
    },
    openManual() {
      window.open('/manual')
    },
    openSettings() {
      window.open('/#/settings/vehicles')
    },
    async openOdoo() {
      const partnerData = getPartnerData(window.location.hostname)
      const session = await Auth.currentSession()
      console.log(session)
      if (this.user.attributes.odoo !== true && this.user.attributes.odoo !== 'true') {
        window.open(this.user.attributes.odoo)
      } else {
        window.open(`/odoo/?clientId=${partnerData && partnerData.cognitoClientId}&sub=${session.accessToken.payload.sub}`)
      }
    },
    async logout() {
      this.$store.commit('transient/SET_LOADING', true)
      await this.$store.dispatch('user/logout')
      window.location.href = '/'
    }
  }
}
</script>
