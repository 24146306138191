import { vm } from '@/main'
import Vue from 'vue'
import * as alertType from '@/alerts/alertType'
import * as utils from '@/utils/utils'
import { getSpeedLimitByPosition } from '@/api/here'
import { traccar } from '@/api/traccar-api'

export function convertEvents(events, isNew) {
  let filteredData = events.filter(a => {
    const currentAlertType = a.type === 'alarm' ? a.attributes.alarm : a.type
    return a.type === 'alarm' || vm.$store.getters.alerts.find(a => a.notification.type === currentAlertType)
  })
  filteredData = filteredData.sort(function(a, b) {
    const diff = Date.parse(b.serverTime) - Date.parse(a.serverTime)
    if (diff === 0) {
      return b.id - a.id
    }
    return diff
  })

  filteredData.forEach(e => {
    addEventInfo(e, isNew)
  })

  return filteredData
}

export function addEventInfo(e, isNew = false, stop) {
  e.device = vm.$store.getters.devices.find(d => d.id === e.deviceId)
  e.content = getNotificationContent(e, stop)
  e.originalType = e.type
  e.type = e.type === 'alarm' ? e.attributes.alarm : e.type
  e.image = getNotificationImage(e.type)
  e.color = getNotificationColor(e.type)
  e.isNew = isNew
}

function getNotificationContent(notification, stop) {
  if (notification.type === alertType.geofenceExit || notification.type === alertType.geofenceEnter) {
    const geofence = vm.$store.getters.geofences.find(g => g.id === notification.geofenceId)
    return geofence && geofence.name
  }
  if (notification.type === alertType.deviceOverspeed) {
    return Math.round(notification.attributes.speed * 1.85200) + ' Km/h (Max. ' + ~~(notification.attributes.speedLimit * 1.852) + ' km/h)'
  }
  if (notification.type === alertType.deviceFuelDrop) {
    return ' > ' + notification.attributes.fuelDropThreshold + '%'
  }

  if (notification.type === alertType.ignitionOff) {
    const value = stop ? ((new Date(stop.endTime) - new Date(stop.startTime)) - stop.engineHours) / 1000 : 0
    return utils.calculateTimeHHMM(value)
  }

  return ''
}

function getNotificationImage(type) {
  if (type === alertType.ignitionOn || type === alertType.ignitionOff) {
    return 'fas fa-key'
  }
  if (type === alertType.geofenceEnter || type === alertType.geofenceExit) {
    return 'fas fa-draw-polygon'
  }
  if (type === alertType.deviceOverspeed) {
    return 'fas fa-shipping-fast'
  }
  if (type === alertType.alarmSOS) {
    return 'fas fa-exclamation-circle'
  }
  if (type === alertType.alarmPowerOn) {
    return 'fas fa-cog'
  }
  if (type === alertType.deviceFuelDrop) {
    return 'fas fa-gas-pump'
  }
  if (type === alertType.driverChanged) {
    return 'fas fa-address-card'
  }
  if (type === alertType.alarmTemperature) {
    return 'fas fa-thermometer-half'
  }
  if (type === alertType.alarmBreakdown) {
    return 'fas fa-wrench'
  }
  if (type === alertType.alarmIdle) {
    return 'fas fa-pause'
  }
  if (type === alertType.maintenance) {
    return 'fas fa-wrench'
  }
  return ''
}

function getNotificationColor(type) {
  if (type === alertType.ignitionOn || type === alertType.geofenceEnter ||
    type === alertType.driverChanged) {
    return 'green'
  }
  if (type === alertType.ignitionOff || type === alertType.geofenceExit ||
    type === alertType.alarmSOS || type === alertType.deviceOverspeed ||
    type === alertType.deviceFuelDrop) {
    return 'red'
  }
  return 'black'
}

export function getMessage(event) {
  Vue.$log.debug(event.type, ' device: ', event.device)
  let result = event.device && event.device.name
  if (event.content) {
    result += ' - ' + event.content
  }
  return result
}

export async function filterEvent(event, alerts) {
  try {
    const alert = alerts.find(a => a &&
      a.notification.type === event.type &&
      (a.notification.type !== 'alarm' || (a.notification.attributes.alarms === event.attributes.alarm)) &&
      (a.notification.always || vm.$store.getters.devices.find(d => d.alerts.find(a => a.id === a.notification.id))))
    const notification = alert && alert.notification
    if (!notification) {
      Vue.$log.warn('cant find notification', event, alerts)
      return false
    }
    if (event.type === 'geofenceEnter' || event.type === 'geofenceExit') {
      if (notification.attributes.geofences && !notification.attributes.geofences.includes(event.geofenceId)) {
        Vue.$log.debug('ignore event notification', notification, event)
        return false
      }
    }

    if (notification.attributes.checkGeofences === 'onlyInside' && !event.geofenceId) {
      Vue.$log.debug('ignore only inside', notification, event)
      return false
    }

    if (notification.attributes.checkGeofences === 'onlyOutside' && event.geofenceId) {
      Vue.$log.debug('ignore only outside', notification, event)
      return false
    }

    if (notification.attributes.timetable && !notification.attributes.timetable.allWeek) {
      const eventDate = new Date()
      const startDate = new Date(eventDate.toISOString().split('T')[0] + ' ' + notification.attributes.timetable.startTime)
      const endDate = new Date(eventDate.toISOString().split('T')[0] + ' ' + notification.attributes.timetable.endTime)

      if ((eventDate.getDay() === 0 && notification.attributes.timetable.weekDays.sunday) ||
        (eventDate.getDay() === 1 && notification.attributes.timetable.weekDays.monday) ||
        (eventDate.getDay() === 2 && notification.attributes.timetable.weekDays.tuesday) ||
        (eventDate.getDay() === 3 && notification.attributes.timetable.weekDays.wednesday) ||
        (eventDate.getDay() === 4 && notification.attributes.timetable.weekDays.thursday) ||
        (eventDate.getDay() === 5 && notification.attributes.timetable.weekDays.friday) ||
        (eventDate.getDay() === 6 && notification.attributes.timetable.weekDays.saturday)) {
        if (startDate.getTime() < endDate.getTime()) {
          return eventDate.getTime() > startDate.getTime() && eventDate.getTime() < endDate.getTime()
        } else {
          return eventDate.getTime() < endDate.getTime() || eventDate.getTime() > startDate.getTime()
        }
      }
    }

    if (event.type === 'deviceOverspeed' && event.device.attributes.overspeedByRoad) {
      const [position] = await traccar.position(event.positionId, event.device.id).then(r => r.data)
      event.position = position
      const _aboveSpeedLimit = await aboveSpeedLimit(position, event.device)
      if (_aboveSpeedLimit) {
        event.attributes.speedLimit = _aboveSpeedLimit / 1.852
        event.content = `${Math.round(event.attributes.speed * 1.85200)} Km/h (Max. ${_aboveSpeedLimit} Km/h)`
      }
      return _aboveSpeedLimit
    }
  } catch (e) {
    console.error(e)
  }
  return true
}

async function aboveSpeedLimit(position, device) {
  const maxSpeed = await getSpeedLimitByPosition(position)
  if (maxSpeed) {
    if (position.speed * 1.852 > maxSpeed + parseInt(device.attributes.overspeedThreshold || 0)) {
      return maxSpeed
    }
  }
  return false
}

