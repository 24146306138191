<template>
  <div v-if="showNavBar" class="navbar">
    <div class="left">
      <p class="logo"><img :src="logo" alt="logo" class="logo" style="float: left; margin-right: 15px" />{{ title }}</p>
    </div>
    <div v-if="showSearchInput" style="float:right; padding:10px">
      <div style="display: flex">
        <el-input
          v-model="search"
          :placeholder="$t('settings.search')"
        />
        <avatar></avatar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import * as partner from '../../utils/partner'
import Avatar from '@/components/Avatar'

export default {
  components: { Avatar },
  created() {
    Vue.$log.debug('Navbar created')
  },
  mounted() {
    Vue.$log.debug('Navbar mounted')
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    search: {
      get() { return this.$store.getters.search },
      set(value) { this.$store.commit('transient/SET_SEARCH', value) }
    },
    logo() {
      return partner.getLogo()
    },
    showNavBar() {
      return this.$route.path.includes('settings')
    },
    showSearchInput() {
      return this.$route.path.includes('settings')
    },
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ]),
    title() {
      return this.$t(this.$route.meta.title)
    }
  }
}
</script>

<style lang="scss" scoped>
  .navbar {
    height: 50px;
    width: calc(100% - 70px);
    position: absolute;
    left: 64px;
    background: rgba(0, 0, 0, 0);
    z-index: 99;
  }
  .left {
    padding: 10px;
    float: left;
    font-size: 20px;
    //opacity: 0.5;
    font-weight: bold;
  }
  .logo {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    height: 40px;
    line-height:40px;
  }
</style>
