<template>
  <div style="float: right; padding-top: 12px; padding-right: 10px">
    <el-tooltip id="coordsTooltip" class="item" effect="light" placement="bottom">
      <div slot="content">{{ currentPosition.latitude.toFixed(6) }}<br />{{ currentPosition.longitude.toFixed(6) }}</div>
      <i class="fas fa-globe coordsIcon" @click="copy()"></i>
    </el-tooltip>
    <span style="padding-left: 2px">
      <a :href="urlStreet" target="_blank">
        <i class="fas fa-street-view coordsIcon"></i>
      </a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'CopyCoordinates',
  props: {
    currentPosition: {
      type: Object,
      default: () => { return { latitude: 0, longitude: 0 } }
    }
  },
  computed: {
    urlStreet() {
      return `https://www.google.com/maps/@?api=1&map_action=pano&heading=${this.currentPosition.course}&viewpoint=${this.currentPosition.latitude},${this.currentPosition.longitude}`
    }
  },
  methods: {
    copy() {
      const text = this.currentPosition.latitude.toFixed(6) + ',' + this.currentPosition.longitude.toFixed(6)
      navigator.clipboard.writeText(text)
      this.$notify({
        title: this.$t('Copiado'),
        message: text + ' ' + this.$t('copyClipboardMessage'),
        type: 'success'
      })
    }
  }
}
</script>

<style scoped>

</style>
