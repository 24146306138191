import { getServerHost } from './index'
import axios from 'axios'
import { Auth } from '@aws-amplify/auth'
const url = `${window.location.protocol}//${getServerHost()}:${window.location.port}/backend`
import Vue from 'vue'

export default {
  axios: axios.create(),
  getJSessionId() {
    return this.get('/api?username=1')
  },
  async get(path) {
    const session = await Auth.currentSession()
    Vue.$log.info('session', session)
    return this.axios.get(url + path, {
      withCredentials: true,
      headers: {
        'Authorization': `${session.accessToken.getJwtToken()}`
      }
    })
  },
  getEmailAuthHash(email, lastHost) {
    const path = `${url}/api?emailAuthHash=${email}&lastHost=${lastHost}`
    return this.axios.get(path, {
      withCredentials: true
    })
  }
}

