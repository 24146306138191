import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enGBLocale from './enGB'
import ptBRLocale from './ptBR'
import ptPTLocale from './ptPT'
import esCLLocale from './esCL'
import frFRLocale from './frFR'
import store from '../store'
import moment from 'moment'
import Element from 'element-ui'
import pt from 'element-ui/lib/locale/lang/pt'
import es from 'element-ui/lib/locale/lang/es'
import en from 'element-ui/lib/locale/lang/en'
import fr from 'element-ui/lib/locale/lang/fr'
import { getPartnerData } from 'fleetmap-partners'

Vue.use(VueI18n)

const messages = {
  enGB: {
    ...enGBLocale
  },
  ptBR: {
    ...ptBRLocale
  },
  ptPT: {
    ...ptPTLocale
  },
  frFR: {
    ...frFRLocale
  },
  esCL: {
    ...esCLLocale
  },
  esES: {
    ...esCLLocale
  }
}

export const languages = [
  { value: 'en-GB', text: 'English (UK)' },
  { value: 'fr-FR', text: 'Français (France)' },
  { value: 'es-CL', text: 'Español' },
  { value: 'pt-PT', text: 'Português (PT)' },
  { value: 'pt-BR', text: 'Português (BR)' }
]

export function setLanguage(lang) {
  if (lang) {
    const locale = lang.replace('-', '')
    const language = locale.slice(0, 2)
    i18n.locale = locale
    console.log('setting locale and lang', locale, language)
    Vue.config.lang = language
    Vue.moment.locale(language)
    moment.locale(language)
    if (process.env.NODE_ENV === 'production') {
      switch (language) {
        case 'pt':
          Vue.use(Element, { locale: pt })
          break
        case 'fr':
          Vue.use(Element, { locale: fr })
          break
        case 'es':
          Vue.use(Element, { locale: es })
          break
        case 'en':
          Vue.use(Element, { locale: en })
          break
        default:
          console.error('setting pt from', lang, locale, language)
          Vue.use(Element, { locale: pt })
      }
    }
  }
}

export function getLanguageI18n() {
  const lang = getLanguage()
  return lang.slice(0, 2) + '-' + lang.slice(-2)
}

export function getLanguage() {
  if (store) {
    const chooseLanguage = store.getters.name && store.getters.user.attributes && store.getters.user.attributes.lang
    if (chooseLanguage) return chooseLanguage.replace('-', '')
  }
  const language = (navigator.language || navigator.browserLanguage).toLowerCase().replace('-', '')
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (locale.toLowerCase().indexOf(language) > -1) {
      return locale
    }
  }
  if (language.toLowerCase().includes('fr')) {
    return 'frFR'
  }
  if (language.toLowerCase().includes('en')) {
    return 'enGB'
  }
  if (language.toLowerCase().includes('es')) {
    return 'esCL'
  }
  if (language.toLowerCase().includes('pt')) {
    return 'ptBR'
  }
  return (getPartnerData().lang && getPartnerData().lang.replace('-', '')) || 'ptPT'
}
const i18n = new VueI18n({
  locale: getLanguage(),
  fallbackLocale: 'enGB',
  // set locale messages
  messages
})

export default i18n
