import { getPartnerData } from 'fleetmap-partners'

export const fleetmapModules = {
  map: 'map',
  dashboard: 'dashboard',
  scheduler: 'scheduler',
  reports: 'reports',
  odoo: 'odoo',
  tacho: 'tacho',
  legacy: 'legacy',
  settings: 'settings'
}

export function dashboardIsActive(user) {
  return moduleIsActive(user, 'dashboard')
}

export function moduleIsActive(user, module) {
  const partnerData = getPartnerData(window.location.hostname)
  return ((!partnerData.prefs || partnerData.prefs[module] === undefined || partnerData.prefs[module]) &&
      (user.attributes[module] === undefined) && module !== 'legacy') ||
    (user.attributes[module] === true || user.attributes[module] === 'true' ||
      ((module === 'legacy' || module === 'odoo') && user.attributes[module]))
}

export function tachoIsActive(user) {
  const partnerData = getPartnerData(window.location.hostname)
  return partnerData.prefs && partnerData.prefs.tacho && user.attributes.tacho
}

export function reportsIsActive(user) {
  return moduleIsActive(user, 'reports')
}

export function settingsIsActive(user) {
  return moduleIsActive(user, 'settings')
}

export function schedulerIsActive(user) {
  const partnerData = getPartnerData()
  return user.attributes['scheduler'] || (partnerData.prefs && partnerData.prefs.scheduler)
}

export function mapIsActive(user) {
  return moduleIsActive(user, 'map')
}

export function odooIsActive(user) {
  return (user.attributes['odoo'] || user.attributes['odoo13']) && user.attributes['companyId']
}

export function manualIsActive() {
  const partnerData = getPartnerData(window.location.hostname)
  return partnerData.manual
}

