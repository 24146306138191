import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/wait',
    component: () => import('../views/wait/index')
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{ path: '/redirect/:path*', component: () => import('../views/redirect/index') }]
  },
  {
    path: '/login',
    component: () => import('../views/login/index'),
    hidden: true
  },
  {
    path: '/changePassword',
    component: () => import('../views/login/ChangePassword'),
    hidden: true
  },
  {
    path: '/',
    redirect: '/map',
    hidden: false,
    component: Layout,
    children: [{ path: 'map', component: () => import('../views/map/MapView'), name: 'Map', meta: { title: 'map', icon: 'far fa-map' }}]
  },
  {
    path: '/settings/vehicles',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/vehicles/index'), name: 'Vehicles', meta: { title: 'settings.vehicles', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/groups',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/groups/index'), name: 'Groups', meta: { title: 'settings.groups', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/drivers',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/drivers/index'), name: 'Driver', meta: { title: 'settings.drivers', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/users',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/users/index'), name: 'Users', meta: { title: 'settings.users', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/links',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/links/index'), name: 'Links', meta: { title: 'settings.temporary_links', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/geofence',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/geofence/index'), name: 'Geofence', meta: { title: 'settings.zone', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/alerts',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/alerts/index'), name: 'Alerts', meta: { title: 'settings.alerts', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/reports',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/reports/index'), name: 'Reports', meta: { title: 'settings.reports', icon: 'fas fa-sticky-note' }}]
  },
  {
    path: '/settings/maintenance',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/maintenance/index'), name: 'Maintenance', meta: { title: 'settings.maintenance', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/map',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/map/index'), name: 'Map settings', meta: { title: 'settings.map', icon: 'fas fa-cog' }}]
  },
  {
    path: '/settings/profile',
    component: Layout,
    children: [{ path: '', component: () => import('../views/settings/profile/index'), name: 'Profile', meta: { title: 'navbar.profile', icon: 'fas fa-cog' }}]
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router
