<template>
  <div
    style="height: 15px"
    @mouseenter="mouseenter"
    @mouseover="mousemove"
    @mousemove="mousemove"
    @mouseleave="mouseleave"
  >
    <canvas ref="canvas" height="10"></canvas>
    <div ref="tooltip" style="position: fixed">
      <activity-line-tooltip
        v-show="showTooltip && !disableTooltip"
        :tooltip="tooltip"
        :route="device.route"
        :style="`position: relative; left: ${left}px`"
      ></activity-line-tooltip>
    </div>
  </div></template>
<script>

import { traccar } from '@/api/traccar-api'
import { isIdlePosition } from '@/utils/positions'
import ActivityLineTooltip from '@/views/map/ActivityLineTooltip'
const lineWidth = 260
const tooltipOffsetX = 90
const tooltipOffsetY = 100
const leftPadding = 90

export default {
  name: 'ActivityLine',
  components: { ActivityLineTooltip },
  props: {
    disableTooltip: {
      type: Boolean,
      default: () => false
    },
    device: {
      type: Object,
      default: () => { return {} }
    },
    position: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      left: 0,
      showTooltip: false,
      tooltip: { currentDate: 0 }
    }
  },
  watch: {
    position() {
      this.update()
    }
  },
  async mounted() {
    await this.update()
  },
  methods: {
    mouseenter() {
      this.showTooltip = true
    },
    mouseleave() {
      this.showTooltip = false
    },
    mousemove(e) {
      this.left = e.clientX - leftPadding - tooltipOffsetX
      if (this.$refs.tooltip) {
        this.$refs.tooltip.style.top = (e.clientY - tooltipOffsetY) + 'px'
      }
      const index = Math.trunc(e.clientX - leftPadding)
      const date = new Date(new Date().setHours(0, 0, 0, 0) + index / lineWidth * 86400000)
      this.tooltip.currentDate = date.getTime()
      if (this.device && this.device.route && this.device.route.length) {
        const near = this.device.route.filter(p => Math.abs(new Date(p.fixTime).getTime() - date.getTime()) < 60 * 1000)
        if (near.length) {
          this.tooltip.position = near.sort((a, b) =>
            Math.abs(new Date(a.fixTime).getTime() - date.getTime()) -
            Math.abs(new Date(b.fixTime).getTime() - date.getTime())
          )[0]
        }
      }
    },
    async update() {
      const d = this.device
      if (
        (!d.routePending) ||
        (d.route && d.route.length && new Date(d.route[0].fixTime).toDateString() !== new Date().toDateString())
      ) {
        d.routePending = true
        const start = new Date()
        const end = new Date()
        start.setHours(0, 0, 0, 0)
        end.setHours(23, 59, 59, 0)
        try {
          const { route } = await traccar.allInOne(d.id, start, end, ['route'])
          d.route = route
        } catch (e) {
          console.warn(e)
        }
      }
      const canvas = this.$refs.canvas
      if (canvas !== undefined && d.route) {
        const context = canvas.getContext('2d')
        let currentStatus = 0
        let currentLinePosition = 0
        d.route.forEach(p => {
          if (p.attributes.ignition && currentStatus !== 1 && !isIdlePosition(p)) {
            // to ON
            currentLinePosition = this.newIgnitionLine(p, context, currentLinePosition, currentStatus)
            currentStatus = 1
          }

          if (p.attributes.ignition && currentStatus !== 2 && isIdlePosition(p)) {
            // to IDLE
            currentLinePosition = this.newIgnitionLine(p, context, currentLinePosition, currentStatus)
            currentStatus = 2
          }

          if (!p.attributes.ignition && currentStatus !== 0) {
            // to OFF
            currentLinePosition = this.newIgnitionLine(p, context, currentLinePosition, currentStatus)
            currentStatus = 0
          }
        })
        if (d.route.length) {
          const end = this.getEndIndex(d.route.slice(-1)[0].fixTime)
          this.drawLine(context, currentLinePosition, end, currentStatus)
        }
        currentStatus = -1
        this.drawLine(context, currentLinePosition, lineWidth, currentStatus)
      }
    },
    drawLine(context, start, end, status) {
      context.beginPath()
      context.strokeStyle = status === -1 ? '#979797' : (status === 0 ? '#ff0022' : (status === 1 ? '#3D993D' : '#F9B218'))
      context.lineWidth = status === -1 ? 1 : 3
      context.moveTo(start, 4)
      context.lineTo(end, 4)
      context.stroke()
    },
    getEndIndex(date) {
      const dt = date ? new Date(date) : new Date()
      const secs = dt.getSeconds() + (60 * dt.getMinutes()) + (60 * 60 * dt.getHours())
      return (lineWidth * secs) / 86400
    },
    newIgnitionLine(p, context, currentLinePosition, currentStatus) {
      const end = this.getEndIndex(p.fixTime)
      this.drawLine(context, currentLinePosition, end, currentStatus)
      return end
    }
  }
}
</script>
