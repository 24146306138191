<template>
  <el-dropdown>
    <span class="el-dropdown-link" style="padding: 10px">
      <img :src="`https://ui-avatars.com/api/?name=${username}&rounded=true&size=32&color=219FD7`">
    </span>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item disabled>
        {{ username }} ({{ user && user.email }})
      </el-dropdown-item>
      <el-dropdown-item v-if="map" index="/map">
        <i class="el-icon-place"></i> {{ $t('route.map') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="partnerData.legacyMap" @click.native="open(partnerData.legacyMap)">
        <i class="el-icon-map-location"></i> {{ $t('Legacy Map') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="dashboard" @click.native="open('/dashboard')">
        <i class="el-icon-menu"></i> {{ $t('route.dashboard') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="reports" @click.native="open('/reports/')">
        <i class="el-icon-document"></i> {{ $t('route.reports') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="user.attributes.partnerReports || partnerData.partnerReports" @click.native="open(getPartnerReportsUrl)">
        <i class="el-icon-tickets"></i> {{ $t('Relatórios personalizados') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="partnerData.externalReports" @click.native="open(partnerData.externalReports.url)">
        <i :class="partnerData.externalReports.icon || 'el-icon-document-copy'"></i> {{ partnerData.externalReports.title }}
      </el-dropdown-item>
      <el-dropdown-item v-if="manageSchedules" @click.native="open('/reports/schedules/')">
        <i class="el-icon-date"></i> {{ $t('route.scheduler') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="odoo" @click.native="openOdoo">
        <i class="el-icon-truck"></i> {{ $t('route.management') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="tacho" @click.native="open(tachoUrl)">
        <i class="fas fa-digital-tachograph" style="padding: 5px"></i> {{ $t('Tacógrafo') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="settings" @click.native="open('/#/settings/vehicles')">
        <i class="el-icon-setting"></i> {{ $t('route.settings') }}
      </el-dropdown-item>
      <el-dropdown-item v-if="manual" @click.native="open('/manual')">
        <i class="el-icon-collection"></i> {{ $t('route.manual') }}
      </el-dropdown-item>
      <el-dropdown-item v-for="mi in extraMenuItems" :key="mi.title" @click.native="open(mi.url)">
        <i :class="mi.class"></i> {{ $t(mi.title) }}
      </el-dropdown-item>
      <el-dropdown-item divided @click.native="logout">
        <i class="el-icon-switch-button"></i> {{ $t('navbar.logout') }}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  dashboardIsActive,
  manualIsActive,
  mapIsActive, odooIsActive,
  reportsIsActive, schedulerIsActive,
  settingsIsActive,
  tachoIsActive
} from '@/utils/modules'
import store from '@/store'
import { getPartnerData } from 'fleetmap-partners'
import { Auth } from '@aws-amplify/auth'

export default {
  name: 'Avatar',
  computed: {
    ...mapGetters(['user']),
    username() {
      return this.user && this.user.name
    },
    map() {
      return mapIsActive(store.getters.user)
    },
    settings() {
      return settingsIsActive(store.getters.user)
    },
    dashboard() {
      return dashboardIsActive(store.getters.user)
    },
    reports() {
      return reportsIsActive(store.getters.user)
    },
    partnerData() {
      return getPartnerData()
    },
    manual() {
      return manualIsActive()
    },
    tacho() {
      return tachoIsActive(this.user)
    },
    manageSchedules() {
      return schedulerIsActive(store.getters.user)
    },
    odoo() {
      return odooIsActive(store.getters.user)
    },
    extraMenuItems() {
      return Object.keys(this.user.attributes).filter(k => k.startsWith('menuItem')).map(i => {
        const values = this.user.attributes[i].split('|')
        return {
          class: values[0],
          title: values[1],
          url: values[2]
        }
      })
    }
  },
  methods: {
    open: (url) => window.open(url),
    async logout() {
      this.$store.commit('transient/SET_LOADING', true)
      await this.$store.dispatch('user/logout')
    },
    async openOdoo() {
      const partnerData = getPartnerData(window.location.hostname)
      const session = await Auth.currentSession()
      console.log(session)
      if (this.user.attributes.odoo !== true && this.user.attributes.odoo !== 'true') {
        window.open(this.user.attributes.odoo)
      } else {
        window.open(`/odoo/?clientId=${partnerData && partnerData.cognitoClientId}&sub=${session.accessToken.payload.sub}`)
      }
    },
    partnerReports() {
      window.open(this.getPartnerReportsUrl())
    },
    tachoUrl() {
      return this.user.attributes.tacho &&
      this.user.attributes.tacho.startsWith &&
      this.user.attributes.tacho.startsWith('http') ? this.user.attributes.tacho
        : '/reports/tacho/'
    },
    getPartnerReportsUrl() {
      const userUrl = this.user.attributes.partnerReports &&
        this.user.attributes.partnerReports.startsWith &&
        this.user.attributes.partnerReports.startsWith('/')
      if (userUrl) {
        return this.user.attributes.partnerReports
      }
      if (this.user.attributes.partnerReports) {
        return '/reports/partnerReports/'
      }
      return this.partnerData.partnerReports
    }
  }
}
</script>
