import axios from 'axios'
import { getServerHost, newDomain } from './index'

const apiName = 'pinmeapi'
export const pinmeApiBaseUrl = `${window.location.protocol}//` + getServerHost() + `:${window.location.port}/` + apiName + '/'

export const pinmeapi = {
  async get(deviceId) {
    return await axios.get(pinmeApiBaseUrl + deviceId, { withCredentials: true }).then(d => d.data)
  },
  async getAll() {
    return await axios.get(pinmeApiBaseUrl + 'devices/ignitionoff', { withCredentials: true }).then(d => d.data)
  },
  async driverCreatePass(driver) {
    return await axios.post(pinmeApiBaseUrl + 'driver/tempPassword', driver, { withCredentials: true }).then(d => d.data)
  },
  async setCognitoPhoneNumber(driver) {
    return await axios.post(pinmeApiBaseUrl + 'driver/setCognitoPhoneNumber', driver, { withCredentials: true }).then(d => d.data)
  },
  async driverAttribute(deviceId) {
    return await axios.post(pinmeApiBaseUrl + 'driverAttribute/' + deviceId, undefined, { withCredentials: true }).then(d => d.data)
  },
  getDeviceToken(deviceId) {
    return axios.get(pinmeApiBaseUrl + 'tokens/' + deviceId, { withCredentials: true }).then(d => d.data)
  },
  updateDeviceAccumulators(deviceId, accumulators) {
    const body = {
      deviceId: deviceId,
      totalDistance: accumulators.totalDistance,
      hours: accumulators.hours
    }
    return axios.put(pinmeApiBaseUrl + 'devices/' + deviceId + '/accumulators', body, { withCredentials: true })
  },
  addUniqueId(uniqueId) {
    return axios.put(pinmeApiBaseUrl + 'uniqueId', uniqueId, { withCredentials: true })
  },
  updateUniqueId(uniqueId) {
    return axios.post(pinmeApiBaseUrl + 'uniqueId', uniqueId, { withCredentials: true })
  },
  deleteUniqueId(id) {
    return axios.delete(pinmeApiBaseUrl + 'uniqueId/' + id, { withCredentials: true })
  },
  async getUniqueIds() {
    return await axios.get(pinmeApiBaseUrl + 'uniqueId/all', { withCredentials: true }).then(d => d.data)
  },
  addPermission(permission) {
    return axios.post(pinmeApiBaseUrl + 'permissions', permission, { withCredentials: true }).then(d => d.data)
  },
  usersByUser(userId) {
    return axios.get(pinmeApiBaseUrl + 'users/' + userId, { withCredentials: true }).then(d => d.data)
  },
  syncUserData(user) {
    if (user.readonly) {
      return Promise.resolve()
    }
    return axios.post(pinmeApiBaseUrl + 'syncdata/' + user.id, {}, { withCredentials: true }).then(d => d.data)
  },
  updateUser(user) {
    return axios.put(pinmeApiBaseUrl + 'users/' + user.id, user, { withCredentials: true }).then(d => d.data)
  },
  deleteGeofencesFromGroup(groupId) {
    return axios.delete('/pinmeapi/geofences/' + groupId, { withCredentials: true })
  },
  async getSubUsers(users) {
    const subUsers = []
    for (const u of users) {
      if (u && !u.token && u.id && !u.readonly && u.userLimit) {
        const _users = await pinmeapi.usersByUser(u.id)
        _users.forEach(user => { user.subUserId = u.id })
        subUsers.push(..._users)
      }
    }
    return subUsers
  },
  sendWhatsApp(user, message) {
    return axios.post(pinmeApiBaseUrl + 'whatsapp', { message }, { withCredentials: true }).then(d => d.data)
  },
  sendCommand(user, message) {
    return axios.post(pinmeApiBaseUrl + 'commands', { message }, { withCredentials: true }).then(d => d.data)
  },
  getSpeedingEvents(body) {
    return axios.post(`${window.location.protocol}//` + getServerHost() + `:${window.location.port}` + `${newDomain() ? '/pinmeapi' : ''}/reports/speeding-report/getEvents`, body, { withCredentials: true })
  },
  addAllPermissions(body) {
    return axios.post(pinmeApiBaseUrl + 'permissions/bulk', body, { withCredentials: true }).then(d => d.data)
  }
}
