<template>
  <div style="width: 180px;">
    <div style="height: 50px"></div>
    <div class="arrow_box" style="background-color: rgb(183, 222, 242)">
      <span style="padding: 10px">
        {{ tooltip && tooltip.currentDate && new Date(tooltip.currentDate).toLocaleString() }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityLineTooltip',
  // eslint-disable-next-line vue/require-prop-types
  props: ['tooltip', 'route'],
  data() { return { visible: true } }
}
</script>
<style>

/*https://cssarrowplease.com/*/
.arrow_box {
  position: relative;
  border: 2px solid #000000;
}
.arrow_box:after, .arrow_box:before {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: rgb(183, 222, 242);
  border-width: 10px;
  margin-left: -10px;
}

.arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-top-color: #000000;
  border-width: 13px;
  margin-left: -13px;
}

</style>
