<template>
  <div class="vehicleDetail">
    <a :href="streetViewUrl" target="_blank">
      <el-image
        :key="imageUrl"
        fit="cover"
        :style="`height: ${imageHeight}px; width:100%; margin-top: 13px; margin-bottom: 0`"
        :src="imageUrl"
        alt=""
        @error="imageHeight=0"
        @load="imageHeight=140"
      >
      </el-image>
    </a>
    <div style="padding-left: 6px;padding-right: 6px;">
      <copy-coordinates :current-position="currentPosition" :url-street="googleStreetViewUrl" />
      <div class="title">
        {{ device.name }}
      </div>
      <div class="subtitle">
        {{ device.model }}
      </div>
      <div v-if="!historyMode && device.driver" class="driver">
        <i class="fas fa-user driverIcon" style="padding-right:2px; padding-left:2px"></i>{{ device.driver.name }}
      </div>
      <div v-if="historyMode && currentPosition.attributes.driverUniqueId" class="driver">
        <i class="fas fa-user driverIcon" style="padding-right:2px; padding-left:2px"></i>{{ driverName() }}
      </div>
      <div class="content">
        {{ currentPosition && currentPosition.address && currentPosition.address.replace('&\#39;', '\'') }}
        <div style="padding-top: 5px;">
          <div style="color:#32325D;">
            <span v-if="currentPosition.ignition || currentPosition.speed > 2">
              <i class="fas fa-tachometer-alt speedIcon" style="padding-right:4px"></i>{{ Math.round(currentPosition.speed * 1.852) }} km/h
            </span>
            <span v-else-if="!showStopDate">
              <el-tooltip :content="device.lastStop | formatDate">
                <i class="fas fa-octagon stopIcon"></i>
              </el-tooltip> {{ device.lastStop | formatLastStop }}
            </span>
            <span v-if="currentPosition.ignition && currentPosition.fuelLevel"><i :class="fuelLevelStatus(currentPosition.fuelLevel)" style="padding-right:2px; padding-left:8px"></i>{{ currentPosition.fuelLevel ? currentPosition.fuelLevel : '' }}%</span>
            <span v-if="currentPosition.ignition && currentPosition.attributes.rpm"><i class="fab fa-cloudscale rpmIcon" style="padding-right:2px; padding-left:8px"></i>{{ currentPosition.attributes.rpm ? currentPosition.attributes.rpm : '' }} rpm</span>
          </div>
          <temperature-icons :current-position="currentPosition" :device="device" />
          <doors-icons :current-position="currentPosition" :device="device" />
          <sensor-icons sensor="sensor1" :current-position="currentPosition" :device="device" />
          <sensor-icons sensor="sensor2" :current-position="currentPosition" :device="device" />
          <sensor-icons sensor="sensor3" :current-position="currentPosition" :device="device" />
          <div style="float: right">
            <span v-if="currentPosition.attributes.rain">
              <el-tooltip :content="$t('vehicleDetail.'+currentPosition.attributes.rain)">
                <i class="fak fa-windshield--2-" :style="`color:${currentPosition.attributes.rain === 'rain'?'#3D993D':'#219FD7'}; padding-right:2px; padding-left:2px`"></i>
              </el-tooltip>
            </span>
          </div>
          <span v-if="!routePoint && showStopDate && getDeviceState(device)==='Stopped' && device.lastStop">{{ device.lastStop | formatLastUpdate }}</span>
          <span v-else-if="!routePoint">{{ device.lastUpdate | formatLastUpdate }}</span>
          <span v-if="routePoint">{{ currentPosition.fixTime | moment('LL') }} {{ currentPosition.fixTime | moment('LTS') }}</span>
        </div>
        <IOdometer
          v-if="vehicleType(device, ['machine'], false)"
          class="iOdometer"
          :style="`float:left;margin-right: 4px; margin-top:6px; ${routePoint?'margin-bottom:6px;':''} font-size: 1em;opacity: 0.4`"
          theme="car"
          format="( ddd).d"
          :value="totalDistance"
        ></IOdometer>
        <span v-if="device.position && vehicleType(device, ['machine'], true)" style="float:left;margin-right: 4px; margin-top:10px; font-size: 16px"><i class="fas fa-stopwatch stopwatchIcon"></i>{{ device | formatTotalHours }} h</span>
        <div style="float:left; padding-top: 10px">{{ device.attributes.xpert && device.position.attributes.odometer ? '(can)' : '' }}</div>
        <div style="display: flex">
          <immobilize-button
            v-if="!routePoint"
            :selected-device="device"
          ></immobilize-button>
          <actuator-button
            v-if="!routePoint"
            :device="device"
            style="padding-left: 5px"
          ></actuator-button>
        </div>
        <el-button
          v-if="!routePoint && reportsIsActive(user)"
          icon="el-icon-video-play"
          type="text"
          style="float:right; height: 33px"
          @click="showRoutesChanged"
        >{{ $t('vehicleDetail.show_route') }}</el-button>
        <!--el-button
          v-if="!routePoint"
          icon="el-icon-discover"
          type="text"
          style="float:right"
          @click="directionsClicked"
        >{{ $t('vehicleDetail.directions') }}</el-button-->
        <el-button
          v-if="currentPosition.attributes.ignition && followVehicleEnabled"
          icon="el-icon-video-camera"
          style="float:right"
          type="text"
          @click="toggleFollow"
        >{{ followVehicle ? $t('vehicleDetail.unfollow') : $t('vehicleDetail.follow') }}</el-button>
        <gsm-coverage :position="currentPosition"></gsm-coverage>
      </div>
    </div>
  </div>
</template>

<script>

import * as lnglat from '../../utils/lnglat'
import Vue from 'vue'
import { serverBus, vm } from '@/main'
import ImmobilizeButton from './ImmobilizeButton'
import 'odometer/themes/odometer-theme-car.css'
import IOdometer from 'vue-odometer'
import { mapGetters } from 'vuex'
import ActuatorButton from '@/components/ActuatorButton'
import * as utils from '../../utils/utils'
import { getServerHost } from '@/api'
import CopyCoordinates from '../../components/CopyCoordinates'
import DoorsIcons from '../../components/DoorsIcons'
import SensorIcons from '../../components/SensorIcons'
import TemperatureIcons from '@/components/TemperatureIcons.vue'
import { getDeviceTotalDistance, getDeviceTotalHours } from '@/utils/device'
import { vehicleType } from '@/utils/device'
import GsmCoverage from '@/components/GsmCoverage'
import { reportsIsActive } from '@/utils/modules'

export default {
  name: 'VehicleDetail',
  components: { DoorsIcons, IOdometer, ImmobilizeButton, ActuatorButton, CopyCoordinates, SensorIcons, GsmCoverage, TemperatureIcons },
  static() {
    return {
      mly: null
    }
  },
  filters: {
    formatLastStop: function(value) {
      if (value) {
        return new Date(value).toLocaleTimeString()
      }
    },
    formatDate: function(value) {
      if (value) {
        return new Date(value).toLocaleString()
      }
    },
    formatLastUpdate(value) {
      return vm.$store.getters.showFullDate ? new Date(value).toLocaleString() : vm.$moment(value).fromNow()
    },
    formatTotalHours(device) {
      return Math.round(getDeviceTotalHours(device, device.position))
    }
  },
  data: function() {
    return {
      imageHeight: 0,
      routeMatch: true,
      device: null,
      position: null,
      feature: null,
      i: 0,
      sliderVisible: false,
      imageOk: false,
      lastImageUpdate: new Date(0),
      oldPosition: null,
      fetching: false,
      sequenceKey: null,
      popupOpened: false,
      routePoint: false
    }
  },
  computed: {
    ...mapGetters(['showStopDate', 'historyMode', 'currentTime', 'followVehicle', 'followVehicleEnabled', 'drivers', 'user']),
    totalDistance() {
      let result = getDeviceTotalDistance(this.device, this.currentPosition)
      if (result.toFixed(1).slice(-1) === '0') { result += 0.1 }
      return result
    },
    tripDistance: {
      get() { return vm.$data.distance },
      set(value) { vm.$data.distance = value }
    },
    loadingRoutes: {
      get() { return vm.$data.loadingRoutes },
      set(value) { vm.$data.loadingRoutes = value }
    },
    map() {
      return vm.$static.map
    },
    isMobile() {
      return lnglat.isMobile()
    },
    currentPosition() {
      return this.position ? this.position : this.device.position
    },
    imageUrl() {
      return this.currentPosition && `https://${getServerHost()}/pinmeapi/streetview/image?course=${this.currentPosition.course}&latitude=${this.currentPosition.latitude}&longitude=${this.currentPosition.longitude}`
    },
    streetViewUrl() {
      return this.currentPosition && `https://${getServerHost()}/pinmeapi/streetview/imageUrl?course=${this.currentPosition.course}&latitude=${this.currentPosition.latitude}&longitude=${this.currentPosition.longitude}`
    },
    googleStreetViewUrl() {
      return this.currentPosition && `https://www.google.com/maps/@?api=1&map_action=pano&heading=${this.currentPosition.course}&viewpoint=${this.currentPosition.latitude},${this.currentPosition.longitude}`
    }
  },
  beforeDestroy() {
    Vue.$log.debug('VehicleDetail')
    serverBus.$off('deviceSelectedOnMap', this.deviceSelected)
    serverBus.$off('deviceSelected', this.deviceSelected)
    serverBus.$off('animationEnd', this.devicePositionChanged)
  },
  created() {
    Vue.$log.debug('VehicleDetail')
    serverBus.$on('animationEnd', this.devicePositionChanged)
    serverBus.$on('deviceSelected', this.deviceSelected)
    serverBus.$on('deviceSelectedOnMap', this.deviceSelected)
  },
  mounted: function() {
    Vue.$log.debug('mounted VehicleDetail ', this.device.name, this.device, this.feature)
    // odd width popups are blurry on Chrome, this enforces even widths
    if (Math.ceil(this.$el.clientWidth) % 2) {
      this.$el.style.width = (Math.ceil(this.$el.clientWidth) + 1) + 'px'
    }
  },
  methods: {
    reportsIsActive,
    vehicleType(device, types, includes) {
      return vehicleType(device, types, includes)
    },
    driverName() {
      const driver = this.drivers.find(d => d.uniqueId === this.currentPosition.attributes.driverUniqueId)
      return driver ? driver.name : this.currentPosition.attributes.driverUniqueId
    },
    fuelLevelStatus(fuelLevel) {
      const fuelLevelStatus = fuelLevel > 40 ? 'fuelLevelNormalIcon' : (fuelLevel > 20 ? 'fuelLevelLowIcon' : 'fuelLevelVeryLowIcon')
      return 'fas fa-gas-pump ' + fuelLevelStatus
    },
    toggleFollow() {
      let value = null
      if (!this.followVehicle || this.device.id !== this.followVehicle.id) {
        value = this.device
      }
      this.$store.dispatch('map/followVehicle', value)
    },
    clickDriver() {
      this.popupOpened = true
    },
    handleLoad(e, img) {
      this.imageUrl = img.src
    },
    devicePositionChanged(deviceId) {
      if (this.device.id === deviceId) {
        if (!lnglat.popUps[deviceId].closed) {
          Vue.$log.debug(this.device.name, 'updating mapillary')
          this.lastImageUpdate = new Date()
          this.position = this.device.position
        }
      }
    },
    deviceSelected(device) {
      Vue.$log.debug('device selected ', device.id)
      if (this.device && this.device.id !== device.id) {
        Vue.$log.debug('removing layers on deviceid, ', this.device.id)
        if (this.historyMode) {
          vm.$store.dispatch('transient/toggleHistoryMode')
        }
      } else {
        Vue.$log.debug('not removing layers on deviceid, ', this.device.id)
      }
    },
    getDeviceState(device) {
      return utils.getDeviceState(device.position)
    },
    showRoutesChanged() {
      vm.$store.dispatch('transient/toggleHistoryMode')
    },
    directionsClicked() {
      serverBus.$emit('directionsTo', this.currentPosition.longitude + ',' + this.currentPosition.latitude)
    }
  }
}
</script>

<style  lang="scss">
  @import '../../styles/element-variables.scss';

  @font-face {
    font-family: 'AmazonEmberLight';
    src: url('https://d7eumezdkbwin.cloudfront.net/fonts/AmazonEmber_Lt.woff2') format('woff2'), url('https://d7eumezdkbwin.cloudfront.net/fonts/AmazonEmber_Lt.woff') format('woff');
  }

  @font-face {
    font-family: 'AmazonEmber';
    src: url('https://d7eumezdkbwin.cloudfront.net/fonts/AmazonEmber_Rg.woff2') format('woff2'), url('https://d7eumezdkbwin.cloudfront.net/fonts/AmazonEmber_Rg.woff') format('woff');
  }

  @font-face {
    font-family: 'AmazonEmberBold';
    src: url('https://d7eumezdkbwin.cloudfront.net/fonts/AmazonEmber_Bd.woff2') format('woff2'), url('https://d7eumezdkbwin.cloudfront.net/fonts/AmazonEmber_Bd.woff') format('woff');
  }

  .vehicleDetail {
    z-index:999 ;
    font-family: AmazonEmber,serif;
    padding: 0 0 2px;
  }

  .coordsIcon{
    color: $--color-primary
  }

  .marker {width:0; height:0;}
  .marker  span {
      display:flex;
      justify-content:center;
      align-items:center;
      box-sizing:border-box;
      width: 36px;
      height: 36px;
      color:#fff;
      background: $--color-success;
      border:solid 1px;
      border-radius: 0 50% 50% 50%;
      box-shadow:0 0 2px #000;
      cursor: pointer;
      transform-origin:0 0;
      transform: rotateZ(-135deg);
  }
  .finish span {
      background: $--color-danger;
  }
  .speed span {
    background: #fff;
    color:#000000;
    border: 3px solid #ff0000;
    width: 26px;
    height: 26px;
    border-radius: 20px;
    font-size: smaller;
    text-align: center;
  }
  .marker b {transform: rotateZ(135deg)}
  .rotl span {transform: rotateZ(180deg)}
  .rotr span {transform: rotateZ(-90deg)}
  .mapboxgl-popup-content {
    border-radius: 10px;
    padding: 0 0 2px;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #32325D;
    padding-bottom: 2px;
    padding-top: 1px;
    overflow: auto;
  }
  .subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #32325D;
    padding-bottom: 8px;
    overflow: auto;
  }
  .driver {
    font-size: 13px;
    color: #32325D;
    float:left;
    width: 100%;
    overflow: auto;
    line-height: normal;
    padding-bottom: 4px;
  }
  .content {
    font-size: 13px;
    color: #8898AA;
    width: 100%;
    overflow: auto;
    line-height: normal;
  }
  .mapboxgl-popup-content {
    min-width: 270px;
  }
  .fuelLevelNormalIcon {
    color: $--color-success
  }
  .fuelLevelLowIcon {
    color: $--color-warning
  }
  .fuelLevelVeryLowIcon {
    color: $--color-danger
  }
  .speedIcon {
    color: $--color-success
  }
  .stopIcon {
    color: $--color-danger
  }
  .rpmIcon {
    color: $--color-primary
  }
  .driverIcon {
    padding-left: 2px;
    width: 18px;
    color: $--color-primary
  }
</style>
