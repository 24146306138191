
export const areaSelected = 'areaSelected'
export const eventSelected = 'eventSelected'
export const eventsLoaded = 'eventsLoaded'
export const newEventReceived = 'newEventReceived'
export const deviceSelected = 'deviceSelected'
export const deviceSelectedOnMap = 'deviceSelectedOnMap'
export const deviceChanged = 'deviceChanged'
export const showRoutesChanged = 'showRoutesChanged'
export const posChanged = 'posChanged'
export const tripChanged = 'tripChanged'
export const mapShow = 'mapShown'
export const dataLoaded = 'dataLoaded'
export const devicesLoaded = 'devicesLoaded'
export const modelsLoaded = 'modelsLoaded'
export const autoSliderChange = 'autoSliderChange'
export const movePOI = 'movePOI'
export const updateGeofence = 'updateGeofence'

export const routePlay = 'routePlay'
export const routeMatchFinished = 'routeMatchFinished'

export const toogleFuelChart = 'toogleFuelChart'
export const toogleRPMChart = 'toogleRPMChart'
export const toogleSpeedChart = 'toogleSpeedChart'
export const toogleEventChart = 'toogleEventChart'
export const toggleTempChart = 'toggleTempChart'
